<template></template>
<script>
import exponea from '@/plugins/exponea';

export default {
	name: 'ExponeaAttribute',
	props: {
		fields: {
			type: Object,
		},
	},

	async mounted() {
		const { ee } = this.$route.query;

		if (!ee) {
			return;
		}

		const email = atob(ee).trim().toLowerCase();
		const attributeToChange = {};

		attributeToChange[this.fields.attribute_to_change] =
			this.fields.change_to === 'true';

		await exponea.identify(email);
		exponea.update(attributeToChange);
		const data = {
			...attributeToChange,
			email,
			location: window.location.href,
			tracking_engine: this.fields.company,
		};
		exponea.track('update_attributes', data);
	},
};
</script>
