<template>
	<div
		class="py-10 px-5 lg:px-28 xl:px-40 2xl:px-64"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<div
			class="flex flex-col lg:flex-row justify-between items-start lg:items-center"
		>
			<div class="lg:w-1/2 mb-10 lg:mb-0 lg:mr-4">
				<h4
					class="text-left"
					:class="getTextColour(fields.background_colour.value)"
				>
					{{ fields.small_title }}
				</h4>
				<h2
					v-html="fields.large_title"
					class="text-left"
					:class="getTextColour(fields.background_colour.value)"
				></h2>
				<p
					class="wysiwyg text-left xl:w-10/12"
					:class="getTextColour(fields.background_colour.value)"
					v-html="fields.content"
				></p>
				<router-link
					v-if="
						!isExternalLink(fields.button_link) &&
						fields.show_button
					"
					:to="generateUrl(fields.button_link)"
					class="standard-button block w-full lg:w-1/2"
					:class="getButtonStyle(fields.button_colour.value)"
					>{{ fields.button_text }}</router-link
				>
				<a
					v-if="
						isExternalLink(fields.button_link) && fields.show_button
					"
					:href="generateUrl(fields.button_link)"
					class="standard-button block w-full lg:w-1/2"
					:class="getButtonStyle(fields.button_colour.value)"
					>{{ fields.button_text }}</a
				>
			</div>
			<div class="grid grid-cols-2 grid-rows-2 gap-6 lg:mt-12 lg:ml-4">
				<img
					loading="lazy"
					alt="Image"
					v-for="(item, i) in fields.images"
					:key="i"
					:src="item.image"
					class="max-w-full"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TwoColumnFourImagesBlock',
	props: {
		fields: {
			type: Object,
		},
	},
};
</script>

<style scoped></style>
