<template>
	<div
		:id="fields.section_name"
		class="py-10 pl-5 lg:pl-28 xl:pl-40 2xl:pl-64"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<div class="flex justify-end">
			<img
				loading="lazy"
				class="m-1.5 w-10 rotate-90 cursor-pointer"
				alt="Scroll to carousel previous image"
				:src="carouselArrowPath"
				@click="carouselPrevious"
				@keypress="carouselPrevious"
			/>
			<img
				loading="lazy"
				class="m-1.5 w-10 -rotate-90 cursor-pointer"
				alt="Scroll to carousel next image"
				:src="carouselArrowPath"
				@click="carouselNext"
				@keypress="carouselNext"
			/>
		</div>
		<carousel
			ref="mainCarousel"
			:settings="settings"
			:breakpoints="breakpoints"
		>
			<slide
				class="flex flex-col !justify-start !items-start px-2"
				v-for="(item, index) in fields.images"
				:key="index"
			>
				<div
					class="image-row-image-container w-full"
					v-bind:style="{
						backgroundImage:
							'url(' + item.image.sizes.cote_image_row + ')',
					}"
				></div>
				<div class="text-left w-full">
					<h4
						v-if="item.show_text"
						:class="getTextColour(fields.background_colour.value)"
					>
						{{ item.label }}
					</h4>
					<p
						v-if="item.show_text"
						:class="getTextColour(fields.background_colour.value)"
						v-html="item.content"
					></p>
					<router-link
						v-if="
							item.show_button &&
							!isExternalLink(item.button_link)
						"
						:to="generateUrl(item.button_link)"
						class="standard-button mr-auto"
						:class="getButtonStyle(item.button_colour.value)"
						>{{ item.button_text }}</router-link
					>
					<a
						v-if="
							item.show_button && isExternalLink(item.button_link)
						"
						:href="generateUrl(item.button_link)"
						class="standard-button mr-auto"
						:class="getButtonStyle(item.button_colour.value)"
						>{{ item.button_text }}</a
					>
					<!--  Menu item   -->
					<div v-if="item.show_menu_item" class="text-left w-full">
						<h4
							:class="
								getTextColour(fields.background_colour.value)
							"
						>
							{{ item.dish_name }}
						</h4>
						<h4
							class="mt-1"
							:class="
								getTextColour(fields.background_colour.value)
							"
						>
							{{ item.dish_price }}
						</h4>
						<p
							:class="
								getTextColour(fields.background_colour.value)
							"
							v-html="item.dish_description"
						></p>
						<div class="flex justify-start items-center mt-3">
							<img
								loading="lazy"
								alt="Image"
								v-if="item.is_vegetarian"
								src="@/assets/icons-dietary-vegetarian.svg"
								class="mr-1.5"
							/>
							<img
								loading="lazy"
								alt="Image"
								v-if="item.is_vegan"
								src="@/assets/icons-dietary-vegan.svg"
								class="mr-1.5"
							/>
							<img
								loading="lazy"
								alt="Image"
								v-if="item.is_gluten_free"
								src="@/assets/icons-dietary-gluten.svg"
								class="mr-1.5"
							/>
							<p
								class="font-copperplate text-xs mt-0 font-bold"
								:class="
									getTextColour(
										fields.background_colour.value
									)
								"
							>
								{{ item.dish_calories }} KCAL
							</p>
						</div>
					</div>
				</div>
			</slide>
		</carousel>
	</div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import navArrow from '@/assets/booking-widget-down-arrow.svg';
import navArrowCream from '@/assets/booking-widget-down-arrow-cream.svg';

export default {
	name: 'ImageRowBlock',
	components: {
		Carousel,
		Slide,
	},
	props: {
		fields: {
			type: Object,
		},
	},
	data() {
		return {
			darkBackgrounds: [
				'navy',
				'brown',
				'dark-green',
				'copper',
				'transparent',
			],
			settings: {
				itemsToShow: 1.33333333,
				snapAlign: 'start',
			},
			breakpoints: {
				768: {
					itemsToShow: 2.33333333,
					snapAlign: 'start',
				},
				1024: {
					itemsToShow: 2.75,
					snapAlign: 'start',
				},
				1536: {
					itemsToShow: 3.5,
					snapAlign: 'start',
				},
			},
		};
	},
	computed: {
		carouselArrowPath() {
			if (
				this.darkBackgrounds.includes(
					this.fields.background_colour.value
				)
			) {
				return navArrowCream;
			}
			return navArrow;
		},
	},
	methods: {
		carouselNext() {
			this.$refs.mainCarousel.next();
		},
		carouselPrevious() {
			this.$refs.mainCarousel.prev();
		},
	},
};
</script>

<style scoped>
.image-row-image-container {
	height: calc(100vw * 142 / 360);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

@media screen(md) {
	.image-row-image-container {
		height: calc(100vw * 185 / 768);
	}
}

@media screen(lg) {
	.image-row-image-container {
		height: calc(100vw * 192 / 1024);
	}
}

@media screen(2xl) {
	.image-row-image-container {
		height: calc(100vw * 212 / 1536);
	}
}
</style>
