<template>
	<div
		:id="fields?.section_name"
		class="py-10 px-5 lg:px-28 xl:px-40 2xl:px-64 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 xl:gap-20"
		:class="[
			getBackgroundColour(fields.background_colour.value),
			rowsClass,
		]"
	>
		<div
			v-for="(section, i) in fields.paragraphs"
			:key="i"
			class="text-left"
		>
			<img
				loading="lazy"
				alt="Image"
				v-if="fields.show_diamonds_icon"
				src="@/assets/active_menu.png"
				class="w-6"
			/>
			<router-link
				v-if="
					section.link_page_title === true &&
					!isExternalLink(section.title_link)
				"
				:to="generateUrl(section.title_link)"
			>
				<h4 :class="getTextColour(fields.background_colour.value)">
					{{ section.title }}
				</h4>
			</router-link>
			<a
				v-if="
					section.link_page_title === true &&
					isExternalLink(section.title_link)
				"
				:href="generateUrl(section.title_link)"
			>
				<h4 :class="getTextColour(fields.background_colour.value)">
					{{ section.title }}
				</h4>
			</a>
			<h4
				v-if="section.link_page_title === false"
				:class="getTextColour(fields.background_colour.value)"
			>
				{{ section.title }}
			</h4>
			<p
				v-if="section.content"
				class="mt-6 w-10/12 xl:w-11/12"
				v-html="section.content"
			></p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ParagraphGridBlock',
	props: {
		fields: {
			type: Object,
		},
	},
	data() {
		return {
			gridRows: Math.ceil(this.fields.paragraphs.length / 3),
		};
	},
	computed: {
		rowsClass() {
			return `grid-rows-${this.gridRows}`;
		},
	},
};
</script>

<style scoped></style>
