<template>
	<div
		ref="parallaxContainer"
		:id="fields?.section_name"
		class="lg:h-[300vh] w-full py-32 lg:py-52 xl:py-72 px-16 lg:px-28 xl:px40 2xl:px-64 overflow-hidden"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<div
			class="flex justify-center lg:justify-between xl:justify-evenly items-start relative c-overlay h-full"
		>
			<div
				class="images mr-2"
				:style="{
					transform: `translateY(${distanceScrolledPastTop * 0.1}px)`,
				}"
			>
				<div>
					<img
						loading="lazy"
						alt="Image"
						:src="
							fields.top_left_image.sizes
								.cote_image_size_4_portrait
						"
						class="max-w-full mx-auto my-[110%]"
					/>
				</div>
				<div class="lg:block">
					<img
						loading="lazy"
						alt="Image"
						:src="
							fields.bottom_left_image.sizes
								.cote_image_size_4_portrait
						"
						class="max-w-full my-[110%]"
					/>
				</div>
			</div>
			<div
				class="images ml-2"
				:style="{
					transform: `translateY(${distanceScrolledPastTop * 0.1}px)`,
				}"
			>
				<div>
					<img
						loading="lazy"
						alt="Image"
						:src="
							fields.top_right_image.sizes
								.cote_image_size_4_portrait
						"
						class="max-w-full mx-auto mb-[100%]"
					/>
				</div>
				<div class="lg:block">
					<img
						loading="lazy"
						alt="Image"
						:src="
							fields.middle_right_image.sizes
								.cote_image_size_4_portrait
						"
						class="max-w-full my-[100%]"
					/>
				</div>
				<div class="lg:block">
					<img
						loading="lazy"
						alt="Image"
						:src="
							fields.bottom_right_image.sizes
								.cote_image_size_4_portrait
						"
						class="max-w-full m1-[100%]"
					/>
				</div>
			</div>
			<div
				class="absolute mx-aut mt-28 z-20"
				:style="{
					transform: `translateY(${distanceScrolledPastTop * 0.3}px)`,
				}"
			>
				<div class="rotate-90">
					<div class="c-diamond"></div>
				</div>
			</div>
			<h1
				:style="{
					transform: `translateY(${distanceScrolledPastTop * 0.6}px)`,
				}"
				class="font-mrseaves text-herb absolute mx-auto z-30 text-4xl md:text-5xl lg:text-8xl xl:text-9xl leading-[3.5rem] lg:leading-[11.5rem] xl:leading-[13.5rem]"
			>
				{{ fields.text }}
			</h1>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ParallaxImageGridBlock',
	props: {
		fields: {
			type: Object,
		},
	},
	data() {
		return {
			containerDistanceFromPageTop: 0,
			containerHeight: 0,
			scrollTop: 0,
			distanceScrolledPastTop: 0,
		};
	},
	created() {
		window.addEventListener('scroll', this.handleScroll);
	},
	mounted() {
		this.containerDistanceFromPageTop =
			window.pageYOffset +
			this.$refs.parallaxContainer.getBoundingClientRect().top;
		this.containerHeight = this.$refs.parallaxContainer.offsetHeight;
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		handleScroll(event) {
			// Save the current scroll top value
			this.scrollTop =
				document.documentElement.scrollTop || document.body.scrollTop;
			if (this.scrollTop - this.containerDistanceFromPageTop > 300) {
				this.distanceScrolledPastTop =
					this.scrollTop - this.containerDistanceFromPageTop - 300;
			} else {
				this.distanceScrolledPastTop = 0;
			}

			// If we've scrolled past the top of the container
			if (this.scrollTop > this.containerDistanceFromPageTop) {
				console.log(this.distanceScrolledPastTop);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.c-diamond {
	margin-left: 30px;
	background: transparent;
	border: 2px solid #d7d7cb;
	transform: rotateX(45deg) rotateZ(45deg);
	width: 75vw;
	height: 75vw;
	@media (min-width: 640px) {
		width: 65vw;
		height: 65vw;
	}
	// @media (min-width: 768px) {
	//   width: 50vw;
	//   height: 50vw;
	// }
	@media (min-width: 1024px) {
		width: 80vw;
		height: 80vw;
	}
	@media (min-width: 1280px) {
		width: 75vw;
		height: 75vw;
	}
	@media (min-width: 1536px) {
		width: 55vw;
		height: 55vw;
	}
}
.c-overlay:after {
	content: '';
	position: absolute;
	top: -50%;
	left: -100%;
	width: 200vw;
	height: 200%;
	background: rgba(10, 32, 54, 0.3);
}
</style>
