<template>
	<div
		:id="fields.section_name"
		class="py-10 pl-5 lg:pl-28 xl:pl-40 2xl:pl-64"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<img
			alt="Image"
			loading="lazy"
			src="@/assets/active_menu.png"
			class="w-6 lg:w-10 mb-10"
		/>
		<h3
			class="uppercase text-left mb-12"
			:class="getTextColour(fields.background_colour.value)"
		>
			{{ fields.title }}
		</h3>
		<div v-if="feed">
			<carousel
				:items-to-show="1.5"
				:breakpoints="breakpoints"
				ref="instagramCarousel"
				:wrapAround="true"
				@mouseover="toggleIsCarouselHovered(true)"
				@mouseleave="toggleIsCarouselHovered(false)"
			>
				<slide
					v-for="(item, index) in feed"
					:key="index"
					:class="'mr-10'"
				>
					<div class="carousel__image-wrapper">
						<img
							alt="Image"
							loading="lazy"
							:data-src="item.thumbnail"
							class="lazyload"
						/>
					</div>
				</slide>
			</carousel>
		</div>
		<p style="text-align: left">
			<a
				class="standard-button"
				:class="getButtonStyle(fields.button_colour.value)"
				href="https://instagram.com/coteuk"
				target="_blank"
				rel="noopener noreferrer"
				>more on instagram</a
			>
		</p>
	</div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

export default {
	name: 'InstagramFeedBlock',
	components: {
		Carousel,
		Slide,
	},
	props: {
		fields: {
			type: Object,
		},
	},
	data() {
		return {
			breakpoints: {
				1024: {
					itemsToShow: 4.5,
				},
			},
			isCarouselHovered: false,
		};
	},
	computed: {
		feed() {
			return this.$store.state.instagramFeed;
		},
	},
	methods: {
		async getSquareImage(url) {
			let imgSrc = null;
			await this.cropImageByAspectRatio(url, 1 / 1).then((img) => {
				imgSrc = img;
			});
			return imgSrc;
		},
		handleScroll(e) {
			// This is magic and took me a while to figure out
			// We only want to do the scroll stuff if the user is hovering on the carousel
			if (this.isCarouselHovered) {
				// Prevent the page from scrolling
				e.preventDefault();
				e.stopPropagation();

				// Check for the direction of scroll and use the carousel API in response
				if (e.deltaY < 0) {
					this.$refs.instagramCarousel.prev();
				} else {
					this.$refs.instagramCarousel.next();
				}
			}
		},
		toggleIsCarouselHovered(value) {
			this.isCarouselHovered = value;
		},
	},
	created() {
		window.addEventListener(
			'wheel',
			(e) => {
				this.handleScroll(e);
			},
			{ passive: false }
		);
	},
	destroyed() {
		indow.removeEventListener(
			'wheel',
			(e) => {
				this.handleScroll(e);
			},
			{ passive: false }
		);
	},
};
</script>

<style lang="scss">
.carousel__image-wrapper {
	padding-bottom: 100%;
	position: relative;
	width: 100%;
	overflow: hidden;
	img {
		position: absolute;
	}
}
</style>
