<template>
	<div
		class="w-full pt-4 xl:pb-16 px-5 lg:px-28 xl:px-40 2xl:px-64"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<div
			class="w-11/12 xl:w-full freeTextBlock"
			:class="getTextColour(fields.background_colour.value)"
			v-html="fields.content"
		></div>
	</div>
</template>

<script>
export default {
	name: 'FreeTextBlock',
	props: {
		fields: {
			type: Object,
		},
	},
};
</script>

<style>
.freeTextBlock ul li {
	font-family: parisineregular;
	list-style: disc;
	font-size: 18px;
	line-height: 26px;
	letter-spacing: 2px;
	margin-left: 20px;
}
</style>
