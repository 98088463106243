<template>
	<div
		class="w-full py-10"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<div class="table-wrapper">
			<table :class="getTextColour(fields.background_colour.value)">
				<tr>
					<th scope="col">
						<h3
							:class="
								getTextColour(fields.background_colour.value)
							"
						>
							DISH
						</h3>
					</th>
					<th scope="col">
						<h3
							:class="
								getTextColour(fields.background_colour.value)
							"
						>
							REPLACE
						</h3>
					</th>
					<th scope="col">
						<h3
							:class="
								getTextColour(fields.background_colour.value)
							"
						>
							WITH
						</h3>
					</th>
					<th scope="col">
						<h3
							:class="
								getTextColour(fields.background_colour.value)
							"
						>
							NEW KCAL
						</h3>
					</th>
				</tr>
				<tr v-for="row in fields.menu_swaps" :key="row">
					<td>
						{{ row.dish }}
					</td>
					<td>
						{{ row.replace }}
					</td>
					<td>
						{{ row.with }}
					</td>
					<td>
						{{ row.new_kcal }}
					</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MenuSwapsBlock',
	props: {
		fields: {
			type: Object,
		},
	},
};
</script>

<style scoped lang="scss">
.table-wrapper {
	width: auto;
	border: 1px solid grey;
	margin: auto;
	padding: 10px;
	display: inline-block;
	max-width: 1100px;
	width: 60vw;
	position: relative;
}

@media (max-width: 900px) {
	.table-wrapper {
		overflow: scroll;
	}
}

@media (max-width: 1380px) {
	.table-wrapper {
		width: 90vw;
		margin: 20px;
	}
}

table {
	table-layout: fixed;
	width: 100%;
	min-width: 690px;
}

th {
	padding: 0 60px 10px 10px;
	white-space: nowrap;

	h3 {
		text-align: left;
		margin: 0;
	}
}

tr {
	margin: 0px 10px;
}

tr:not(:last-child) {
	border-bottom: 1px solid grey;
}

td {
	word-wrap: break-word;
	padding: 10px 0 10px 10px;
	text-align: left;
}
</style>
