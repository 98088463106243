<template>
	<div
		class="px-5 lg:px-28 xl:px-40 2xl:px-64"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<h5
			v-if="fields.form_header_text"
			v-html="fields.form_header_text"
			:class="getTextColour(fields.background_colour.value)"
		></h5>
		<NewsLetterForm></NewsLetterForm>
		<h5
			v-if="fields.form_footer_text"
			v-html="fields.form_footer_text"
			:class="getTextColour(fields.background_colour.value)"
		></h5>
	</div>
</template>

<script>
import NewsLetterForm from '@/components/NewsLetterForm.vue';

export default {
	name: 'NewsletterFormBlock',
	components: {
		NewsLetterForm,
	},
	props: {
		fields: {
			type: Object,
		},
	},
};
</script>

<style scoped></style>
