<template>
	<section
		v-if="fields.is_active"
		:class="getBackgroundColour(fields.background_colour.value)"
		class="overflow-auto"
	>
		<h2 v-if="fields.brasserie_list_heading_text">
			{{ fields.brasserie_list_heading_text }}
		</h2>
		<div v-if="fields.brasserie_list_show_navigation.length > 0">
			<h3 class="mt-8">Alphabetical search</h3>
			<ul class="flex justify-center mt-3 flex-wrap mx-4">
				<li
					v-for="letter in brasserieFirstLetters"
					:key="letter"
					class="mx-2"
				>
					<a
						:href="`#${letter}`"
						class="text-lg font-bold underline cursor-pointer"
						>{{ letter }}</a
					>
				</li>
			</ul>
		</div>
		<ul class="w-full flex flex-col items-center">
			<li
				v-for="brasserie in brasseries"
				:key="brasserie.ID"
				class="w-11/12 md:w-8/12 border border-solid border-brand border-l my-3 md:my-6 p-6 max-w-3xl flex flex-col items-center"
			>
				<a :id="brasserie.post_title[0]" class="anchor"></a>
				<h3 class="mt-0">{{ brasserie.post_title }}</h3>
				<p>{{ brasserie.address }}</p>
				<div class="my-3 flex flex-wrap justify-center">
					<a
						:href="`tel:${brasserie.phone_number}`"
						class="mx-2 whitespace-nowrap"
						>{{ brasserie.phone_number }}</a
					>
					<a :href="`mailto:${brasserie.email}`" class="mx-2">{{
						brasserie.email
					}}</a>
				</div>
				<div class="w-full">
					<router-link
						:to="this.getBrasserieLink(brasserie.ID)"
						class="standard-button mt-4 mx-2 w-full md:w-5/12"
						:class="getButtonStyle(fields.button_colour.value)"
						>View Restaurant</router-link
					>
					<router-link
						:to="this.getBrasserieMenusLink(brasserie.ID)"
						class="standard-button mt-4 mx-2 w-full md:w-5/12"
						:class="getButtonStyle(fields.button_colour.value)"
						>View Menus</router-link
					>
				</div>
			</li>
		</ul>
	</section>
</template>

<script>
export default {
	name: 'BrasserieList',
	data() {
		return {};
	},
	props: {
		fields: {
			type: Object,
		},
	},
	computed: {
		brasseries() {
			if (this.fields.brasserie_list_display_type === 'all') {
				return this.$store.state.restaurants;
			}
			return this.$store.state.restaurants.filter((restaurant) =>
				this.fields.brasserie_selection.includes(restaurant.ID)
			);
			// return this.$store.state.restaurants
		},
		brasserieFirstLetters() {
			const firstLetters = [];
			for (const brasserie of this.brasseries) {
				const firstLetter = brasserie.post_title[0];
				if (!firstLetters.includes(firstLetter))
					firstLetters.push(firstLetter);
			}
			return firstLetters;
		},
	},
	methods: {},
	mounted() {},
};
</script>

<style lang="scss" scoped>
.anchor {
	position: relative;
	top: -150px;
}
</style>
