<template>
	<div
		:id="fields.section_name"
		class="w-full py-10 lg:py-20 overflow-x-hidden"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<!--  Morning  -->
		<div
			class="w-full flex flex-col lg:flex-row-reverse lg:justify-start relative"
		>
			<div
				class="text-left w-10/12 lg:w-6/12 relative lg:mt-10 mb-10 lg:mb-20 pl-5 lg:px-0 lg:pl-10 lg:pl-28 xl:pl-24"
			>
				<div class="">
					<img
						loading="lazy"
						alt="Image"
						src="@/assets/active_menu.png"
						class="w-7 lg:w-10 mb-4"
					/>
					<h2>{{ fields.morning_section_title }}</h2>
					<h4
						class="md:w-10/12 lg:w-9/12"
						v-html="fields.morning_content"
					></h4>
					<div class="flex justify-start items-start">
						<div>
							<div class="w-[220px] lg:w-[280px]">
								<router-link
									v-if="
										fields.morning_show_button &&
										fields.morning_button_link_type ==
											'internal' &&
										!isExternalLink(
											fields.morning_button_link
										)
									"
									:to="
										generateUrl(fields.morning_button_link)
									"
									class="standard-button w-full"
									:class="
										getButtonStyle(
											fields.morning_button_colour.value
										)
									"
									>{{
										fields.morning_button_text
									}}</router-link
								>
								<a
									v-if="
										fields.morning_show_button &&
										fields.morning_button_link_type ==
											'external'
									"
									:href="
										generateUrl(
											fields.morning_button_link_external
										)
									"
									class="standard-button w-full"
									:class="
										getButtonStyle(
											fields.morning_button_colour.value
										)
									"
									>{{ fields.morning_button_text }}</a
								>
							</div>
							<div class="w-[220px] lg:w-[280px]">
								<router-link
									v-if="
										fields.morning_show_second_button &&
										fields.morning_second_button_link_type ==
											'internal' &&
										!isExternalLink(
											fields.morning_second_button_link
										)
									"
									:to="
										generateUrl(
											fields.morning_second_button_link
										)
									"
									class="standard-button mt-4 w-full"
									:class="
										getButtonStyle(
											fields.morning_second_button_colour
												.value
										)
									"
									>{{
										fields.morning_second_button_text
									}}</router-link
								>
								<a
									v-if="
										fields.morning_show_second_button &&
										fields.morning_second_button_link_type ==
											'external'
									"
									:href="
										generateUrl(
											fields.morning_second_button_link_external
										)
									"
									class="standard-button mt-4 w-full"
									:class="
										getButtonStyle(
											fields.morning_second_button_colour
												.value
										)
									"
									>{{ fields.morning_second_button_text }}</a
								>
							</div>
						</div>
						<img
							loading="lazy"
							alt="Image"
							:src="fields.morning_animation"
							class="max-w-full ml-10 mt-16 lg:ml-20 lg:mt-20"
						/>
					</div>
				</div>
			</div>
			<div
				class="flex justify-start items-start w-10/12 lg:w-6/12 relative"
			>
				<div class="relative z-20">
					<img
						loading="lazy"
						alt="Image"
						:src="
							fields.morning_main_image.sizes.meals_large_portrait
						"
						class="max-w-full relative z-20"
					/>
					<p
						v-if="fields.morning_main_image.caption"
						class="text-xs bottom-[0px] image-caption caption-right"
					>
						{{ fields.morning_main_image.caption }}
					</p>
					<marquee-text
						:repeat="6"
						:duration="
							getMarqueeScrollDuration(
								fields.morning_scrolling_text
							)
						"
						class="scrolling-text mt-4 text-grey/30 z-0 absolute top-[87%] left-0"
						>{{
							fields.morning_scrolling_text + '&nbsp;'
						}}</marquee-text
					>
				</div>
				<div class="mt-10 -ml-10 lg:mt-24 lg:-ml-24 relative z-30">
					<img
						loading="lazy"
						alt="Image"
						:src="
							fields.morning_small_image.sizes
								.meals_small_portrait
						"
						class="c-image-offset bottom-right max-w-full"
						:class="
							getBackgroundColour(
								fields.morning_small_image_background_colour
									.value
							)
						"
					/>
					<p
						v-if="fields.morning_small_image.caption"
						class="text-xs bottom-[-10px] xl:bottom-[-17px] image-caption caption-right-offset"
					>
						{{ fields.morning_small_image.caption }}
					</p>
				</div>
			</div>
		</div>
		<!--  Noon  -->
		<div class="w-full flex flex-col lg:flex-row mt-28 lg:mt-64">
			<div
				class="text-left w-10/12 lg:w-6/12 relative pl-5 lg:pl-28 xl:pl-40 2xl:pl-64 lg:mt-10"
			>
				<div class="flex justify-start items-start">
					<div>
						<img
							loading="lazy"
							alt="Image"
							src="@/assets/active_menu.png"
							class="w-7 lg:w-10 mb-4"
						/>
						<h2>{{ fields.noon_section_title }}</h2>
					</div>
					<img
						loading="lazy"
						alt="Image"
						:src="fields.noon_animation"
						class="hidden lg:block max-w-full ml-32 -mt-16"
					/>
				</div>
				<h4
					class="md:w-10/12 lg:w-7/12"
					v-html="fields.noon_content"
				></h4>
				<div class="flex justify-between items-start">
					<div>
						<div class="w-[220px] lg:w-[280px]">
							<router-link
								v-if="
									fields.noon_show_button &&
									fields.noon_button_link_type ==
										'internal' &&
									!isExternalLink(fields.noon_button_link)
								"
								:to="generateUrl(fields.noon_button_link)"
								class="standard-button w-full"
								:class="
									getButtonStyle(
										fields.noon_button_colour.value
									)
								"
								>{{ fields.noon_button_text }}</router-link
							>
							<a
								v-if="
									fields.noon_show_button &&
									fields.noon_button_link_type == 'external'
								"
								:href="
									generateUrl(
										fields.noon_button_link_external
									)
								"
								class="standard-button w-full"
								:class="
									getButtonStyle(
										fields.noon_button_colour.value
									)
								"
								>{{ fields.noon_button_text }}</a
							>
						</div>
						<div class="w-[220px] lg:w-[280px]">
							<router-link
								v-if="
									fields.noon_show_second_button &&
									fields.noon_second_button_link_type ==
										'internal' &&
									!isExternalLink(
										fields.noon_second_button_link
									)
								"
								:to="
									generateUrl(fields.noon_second_button_link)
								"
								class="standard-button mt-4 w-full"
								:class="
									getButtonStyle(
										fields.noon_second_button_colour.value
									)
								"
								>{{
									fields.noon_second_button_text
								}}</router-link
							>
							<a
								v-if="
									fields.noon_show_second_button &&
									fields.noon_second_button_link_type ==
										'external'
								"
								:href="
									generateUrl(
										fields.noon_second_button_link_external
									)
								"
								class="standard-button mt-4 w-full"
								:class="
									getButtonStyle(
										fields.noon_second_button_colour.value
									)
								"
								>{{ fields.noon_second_button_text }}</a
							>
						</div>
					</div>
					<img
						loading="lazy"
						alt="Image"
						:src="fields.noon_animation"
						class="lg:hidden max-w-full ml-10 mt-10"
					/>
				</div>
			</div>
			<div
				class="w-full lg:w-6/12 flex flex-col justify-start items-end relative mt-10 lg:mt-0"
			>
				<div class="relative z-20 w-10/12 lg:w-auto">
					<div class="relative">
						<p
							v-if="fields.noon_main_image.caption"
							class="text-xs bottom-0 image-caption caption-left"
						>
							{{ fields.noon_main_image.caption }}
						</p>
						<img
							loading="lazy"
							alt="Image"
							:src="
								fields.noon_main_image.sizes
									.meals_large_landscape
							"
							class="max-w-full"
						/>
					</div>
					<div
						class="relative z-30 -mt-12 lg:-mt-20 flex justify-center"
					>
						<div class="relative w-6/12 lg:w-auto">
							<p
								v-if="fields.noon_small_image.caption"
								class="text-xs bottom-0 image-caption caption-left"
							>
								{{ fields.noon_small_image.caption }}
							</p>
							<img
								loading="lazy"
								alt="Image"
								:src="
									fields.noon_small_image.sizes
										.meals_small_landscape
								"
								class="c-image-offset bottom-right max-w-full relative z-20"
								:class="
									getBackgroundColour(
										fields
											.noon_small_image_background_colour
											.value
									)
								"
							/>
							<marquee-text
								:repeat="6"
								:duration="
									getMarqueeScrollDuration(
										fields.noon_scrolling_text
									)
								"
								class="scrolling-text mt-4 text-grey/30 absolute top-[45%] lg:top-[60%] xl:top-[30%] -left-[100vw]"
								>{{
									fields.noon_scrolling_text + '&nbsp;'
								}}</marquee-text
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--  Night  -->
		<div
			class="w-full flex flex-col lg:flex-row-reverse lg:justify-start relative mt-20 lg:mt-40 pb-60"
		>
			<div
				class="text-left w-10/12 lg:w-6/12 relative lg:mt-12 mb-20 pl-5 lg:px-0 lg:pl-10 lg:pl-28 xl:pl-24"
			>
				<div class="hidden lg:block h-10 w-10"></div>
				<div class="">
					<div class="flex justify-start items-start">
						<div>
							<img
								loading="lazy"
								alt="Image"
								src="@/assets/active_menu.png"
								class="w-7 lg:w-10 mb-4"
							/>
							<h2>{{ fields.night_section_title }}</h2>
						</div>
						<img
							loading="lazy"
							alt="Image"
							:src="fields.night_animation"
							class="lg:block max-w-full ml-10 -mt-8 lg:ml-28 lg:-mt-12"
						/>
					</div>
					<h4
						class="md:w-10/12 lg:w-9/12"
						v-html="fields.night_content"
					></h4>
					<div class="flex justify-start items-start">
						<div>
							<div class="w-[220px] lg:w-[280px]">
								<router-link
									v-if="
										fields.night_show_button &&
										fields.night_button_link_type ==
											'internal' &&
										!isExternalLink(
											fields.night_button_link
										)
									"
									:to="generateUrl(fields.night_button_link)"
									class="standard-button w-full"
									:class="
										getButtonStyle(
											fields.night_button_colour.value
										)
									"
									>{{ fields.night_button_text }}</router-link
								>
								<a
									v-if="
										fields.night_show_button &&
										fields.night_button_link_type ==
											'external'
									"
									:href="
										generateUrl(
											fields.night_button_link_external
										)
									"
									class="standard-button w-full"
									:class="
										getButtonStyle(
											fields.night_button_colour.value
										)
									"
									>{{ fields.night_button_text }}</a
								>
							</div>
							<div class="w-[220px] lg:w-[280px]">
								<router-link
									v-if="
										fields.night_show_second_button &&
										fields.night_second_button_link_type ==
											'internal' &&
										!isExternalLink(
											fields.night_second_button_link
										)
									"
									:to="
										generateUrl(
											fields.night_second_button_link
										)
									"
									class="standard-button mt-4 w-full"
									:class="
										getButtonStyle(
											fields.night_second_button_colour
												.value
										)
									"
									>{{
										fields.night_second_button_text
									}}</router-link
								>
								<a
									v-if="
										fields.night_show_second_button &&
										fields.night_second_button_link_type ==
											'external'
									"
									:href="
										generateUrl(
											fields.night_second_button_link_external
										)
									"
									class="standard-button mt-4 w-full"
									:class="
										getButtonStyle(
											fields.night_second_button_colour
												.value
										)
									"
									>{{ fields.night_second_button_text }}</a
								>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="flex justify-start w-10/12 lg:w-6/12 relative">
				<div class="relative z-30">
					<img
						loading="lazy"
						alt="Image"
						:src="fields.night_main_image.sizes.meals_large_square"
						class="max-w-full relative z-20"
					/>
					<p
						v-if="fields.night_main_image.caption"
						class="text-xs top-[28px] lg:top-[40px] xl:top-[32px] image-caption caption-right"
					>
						{{ fields.night_main_image.caption }}
					</p>
				</div>
				<div
					class="flex items-end -ml-52 -mb-32 lg:-mb-12 relative z-30"
				>
					<div class="relative">
						<img
							loading="lazy"
							alt="Image"
							:src="
								fields.night_small_image.sizes
									.meals_small_landscape
							"
							class="c-image-offset bottom-left max-w-full relative z-20"
							:class="
								getBackgroundColour(
									fields.night_small_image_background_colour
										.value
								)
							"
						/>
						<p
							v-if="fields.night_small_image.caption"
							class="text-xs top-[60px] lg:top-[unset] lg:bottom-0 image-caption caption-right"
						>
							{{ fields.night_small_image.caption }}
						</p>
					</div>
					<marquee-text
						:repeat="6"
						:duration="
							getMarqueeScrollDuration(
								fields.night_scrolling_text
							)
						"
						class="scrolling-text mt-4 text-grey/30 z-0 absolute top-[80%] -left-full"
						>{{
							fields.night_scrolling_text + '&nbsp;'
						}}</marquee-text
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component';

export default {
	name: 'MealsBlock',
	components: {
		MarqueeText,
	},
	props: {
		fields: {
			type: Object,
		},
	},
};
</script>

<style lang="scss" scoped></style>
