<template>
	<div
		:id="fields.section_name"
		class="w-full py-10 lg:py-20 px-5 lg:px-28 xl:px-40 2xl:px-64 flex justify-between items-center flex-col lg:flex-row relative"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<img
			loading="lazy"
			alt="Image"
			:src="fields.animation.url"
			class="w-8/12 mx-auto lg:hidden"
		/>
		<div class="px-4 lg:px-0 lg:w-4/12">
			<h4
				class="text-left newsletter-subtitle"
				:class="getTextColour(fields.background_colour.value)"
			>
				{{ fields.sub_title }}
			</h4>
			<h2
				class="text-left newsletter-title"
				:class="getTextColour(fields.background_colour.value)"
			>
				{{ fields.title }}
			</h2>
			<p
				class="text-left mt-4 newsletter-content"
				:class="getTextColour(fields.background_colour.value)"
				v-html="fields.content"
			></p>
		</div>
		<div class="lg:w-3/12">
			<img
				loading="lazy"
				alt="Image"
				:src="fields.animation.url"
				class="mx-auto hidden lg:block w-[150px] -mt-10"
			/>
		</div>
		<div class="w-full lg:w-5/12">
			<div id="newsletter" class="py-6">
				<a
					class="standard-button w-full mt-0"
					:href="fields.button_link"
					:class="getButtonStyle(fields.button_colour.value)"
					>{{ fields.button_text }}</a
				>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NewsletterBlock',
	props: {
		fields: {
			type: Object,
		},
	},
	data() {
		return {
			newsletter: {
				name: null,
				email: null,
				birthday: null,
			},
			inFocus: {
				name: false,
				email: false,
				birthday: false,
			},
		};
	},
	methods: {
		submit(e) {
			if (this.$refs.newsletterForm[0].checkValidity()) {
				e.preventDefault();
				window.exponea.track('newsletter', this.newsletter);
			}
		},
	},
};
</script>

<style scoped></style>
