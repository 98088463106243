<template>
	<div
		:id="fields.section_name"
		class="w-full flex flex-col py-10"
		:class="[
			getBackgroundColour(fields.background_colour.value),
			isTextSideLeft
				? 'layout__position--left'
				: 'layout__position--default',
		]"
	>
		<div
			class="w-full lg:w-6/12 xl:w-7/12 text-left px-5 mb-10 lg:flex flex-col"
			:class="[
				isTextSideLeft
					? 'text__spacing--left'
					: 'text__spacing--default',
			]"
		>
			<h4
				class="text-left lg:w-10/12 2xl:w-8/12"
				:class="getTextColour(fields.background_colour.value)"
			>
				{{ fields.title }}
			</h4>
			<h2
				class="text-left lg:mb-5 lg:w-10/12 2xl:w-8/12"
				:class="getTextColour(fields.background_colour.value)"
			>
				{{ fields.sub_title }}
			</h2>
			<p
				class="text-left w-10/12 lg:w-10/12 2xl:w-8/12"
				:class="getTextColour(fields.background_colour.value)"
				v-html="fields.content"
			></p>
			<div
				v-if="fields.show_button"
				class="lg:w-10/12 2xl:w-8/12 text-left"
			>
				<router-link
					v-if="
						fields.button_link_type == 'internal' &&
						!isExternalLink(fields.button_link)
					"
					:to="generateUrl(fields.button_link)"
					class="standard-button"
					:class="getButtonStyle(fields.button_colour.value)"
				>
					{{ fields.button_text }}
				</router-link>
				<a
					v-if="
						fields.button_link_type == 'external' &&
						isExternalLink(fields.button_link_external)
					"
					:href="generateUrl(fields.button_link_external)"
					class="standard-button"
					:class="getButtonStyle(fields.button_colour.value)"
				>
					{{ fields.button_text }}
				</a>
			</div>
		</div>
		<div
			class="w-full lg:w-6/12 relative mb-2 xl:mb-40 flex lg:top-[34px] justify-start"
			:class="[
				isTextSideLeft
					? 'image__container--left'
					: 'image__container--default',
			]"
		>
			<div class="relative" @click="toggleVideo('open')">
				<img
					loading="lazy"
					alt="Image"
					:src="fields.image.sizes['large']"
					class="max-w-full"
				/>
				<p
					v-if="fields.image.caption && isTextSideLeft"
					class="text-xs top-[90px] image-caption caption-left"
				>
					{{ fields.image.caption }}
				</p>
			</div>
		</div>
	</div>
	<div
		ref="videoContainer-video"
		class="hidden fixed top-0 left-0 w-full h-screen z-70 bg-black"
	>
		<x-icon
			class="text-cream w-8 lg:w-10 absolute top-10 right-10 z-70 cursor-pointer"
			@click="toggleVideo('close')"
		></x-icon>
		<iframe
			v-if="iFramePlay"
			ref="headerVideo-video-popup"
			class="w-10/12 m-auto absolute absolute-center z-20 h-1/2 lg:h-2/3"
			:src="fields.video_url"
			title="YouTube video player"
			frameborder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			allowfullscreen
		></iframe>
	</div>
</template>

<script>
import { XIcon } from '@heroicons/vue/solid';

export default {
	name: 'TwoColumnVideoBlock',
	components: {
		XIcon,
	},
	props: {
		fields: {
			type: Object,
		},
	},
	data() {
		return {
			iFramePlay: false,
			isTextSideLeft: this.fields.text_side.value === 'left',
		};
	},
	methods: {
		toggleVideo(type) {
			const ref = 'videoContainer-video';
			if (type === 'open') {
				this.$refs[ref].classList.remove('hidden');
				this.iFramePlay = true;
			}
			if (type === 'close') {
				this.$refs[ref].classList.add('hidden');
				this.iFramePlay = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.image__container {
	&--default {
		@apply pr-7 lg:pr-0;
	}
	&--left {
		@apply flex-row-reverse pl-7 lg:pl-0;
	}
}
.layout__position {
	&--left {
		@apply lg:flex-row;
	}
	&--default {
		@apply lg:flex-row-reverse;
	}
}
.text__spacing {
	&--left {
		@apply lg:pl-28 xl:pl-40 2xl:pl-64 items-start;
	}
	&--default {
		@apply lg:pr-28 xl:pr-40 2xl:pr-64 items-end;
	}
}
</style>
