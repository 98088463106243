<template>
	<div
		class="flex flex-col lg:flex-row justify-between pt-5 pb-10 px-5 lg:px-28 xl:px-40 2xl:px-64"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<div class="w-full lg:w-1/2 lg:mr-20">
			<h2
				class="text-left w-8/12"
				:class="getTextColour(fields.background_colour.value)"
			>
				{{ fields.title }}
			</h2>
			<p
				class="text-left"
				:class="getTextColour(fields.background_colour.value)"
				v-html="fields.content"
			></p>
		</div>
		<div class="w-full lg:w-1/2 lg:ml-20">
			<fresh-desk-form
				button_title="Send"
				:form_title="''"
			></fresh-desk-form>
		</div>
	</div>
</template>

<script>
import FreshDeskForm from '@/components/FreshDeskForm.vue';

export default {
	name: 'ContactFormBlock',
	components: {
		FreshDeskForm,
	},
	props: {
		fields: {
			type: Object,
		},
	},
};
</script>

<style lang="scss" scoped></style>
