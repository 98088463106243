<template>
	<div>
		<div
			v-for="n in lines"
			:key="n"
			class="mx-1 h-full border-r-grey w-1/12"
			:class="getBorderClass"
		></div>
	</div>
</template>

<script>
export default {
	name: 'VerticalLines',
	props: {
		borderWidth: {
			type: String,
		},
		lines: {
			type: Number,
			default: 12,
		},
	},
	computed: {
		getBorderClass() {
			if (this.borderWidth === 'thick') {
				return 'border-r-4';
			}
			return 'border-r-2';
		},
	},
};
</script>

<style scoped></style>
