<template>
	<div class="bg-noise-pale" id="preferences-container">
		<div
			id="social_competition"
			class="md:w-6/12 lg:w-5/12 mx-auto px-1 py-10"
		>
			<social-competition
				:company="fields.company"
				:competition-code="fields.competition_code"
				:competition-name="fields.competition_name"
				:competition-closed="competitionClosed"
				:competition-not-open="competitionNotOpen"
				:has-partner-terms="fields.partner_terms.length > 0"
				:hide-cah-subscribe="fields.hide_cah_subscribe"
				:hide_competition_terms="fields.hide_competition_terms"
				:hide_dietary_requirements="fields.hide_dietary_requirements"
				:hide-corporate-subscribe="fields.hide_corporate_subscribe"
				:hide-cote-subscribe="fields.hide_cote_subscribe"
				:partner-terms-required="fields.partner_terms_required"
				:show-favourite-restaurant="fields.show_favourite_restaurant"
				:show-postcode="fields.show_postcode"
			>
				<template v-slot:not_open>
					<div class="max-w-35 mx-auto text-center pb-5">
						<div
							class="article-text max-w-26 mx-auto text-center"
							v-html="fields.not_open_message"
						></div>
					</div>
				</template>
				<template v-slot:closed>
					<div class="max-w-35 mx-auto text-center pb-5">
						<div
							class="article-text max-w-26 mx-auto text-center"
							v-html="fields.closed_message"
						></div>
					</div>
				</template>
				<template v-slot:competition-terms class="w-full">
					<div v-html="fields.competition_terms"></div>
				</template>
				<template
					v-slot:partner-terms
					class="w-full"
					v-html="fields.partner_terms"
				>
				</template>
				<template v-slot:intro>
					<div class="max-w-35 mx-auto text-center pt-10">
						<h2
							class="text-h4 mb-half font-brand tracking-small uppercase text-center"
						>
							{{ fields.post_title }}
						</h2>
						<div class="max-w-35 mx-auto text-center pb-5">
							<div
								class="article-text max-w-26 mx-auto text-center"
								v-html="fields.intro_message"
							></div>
						</div>
					</div>
				</template>
				<template v-slot:thankyou class="w-full text-center py-2">
					<h2
						class="text-h4 mb-half font-brand tracking-small uppercase text-center"
					>
						Thank You
					</h2>
					<div class="max-w-35 mx-auto text-center pb-5">
						<div
							class="article-text max-w-26 mx-auto text-center"
							v-html="fields.thank_you_message"
						></div>
					</div>
					<span class="heading-line mb-1"></span>
				</template>
			</social-competition>
		</div>
	</div>
</template>

<script>
import SocialCompetition from '@/components/SocialCompetition.vue';

export default {
	name: 'SocialCompetitionBlock',
	props: {
		fields: {
			type: Object,
		},
	},
	components: {
		SocialCompetition,
	},
	created() {},
	computed: {
		competitionNotOpen() {
			if (Date.now() < new Date(this.fields.start_date)) {
				return true;
			}
			return false;
		},
		competitionClosed() {
			if (Date.now() > new Date(this.fields.end_date)) {
				return true;
			}
			return false;
		},
	},
};
</script>

<style>
.article-text {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.article-text strong {
	font-weight: bold !important;
}
.article-text a {
	text-decoration: underline;
}
.article-text ul li {
	font-family: parisineregular;
	list-style: disc;
}
</style>
