<template>
	<div
		:id="fields.section_name"
		class="w-full px-5 lg:px-28 xl:px-40 2xl:px-64 py-10 xl:pb-24 relative z-20 lg:flex lg:justify-between lg:flex-row-reverse lg:items-start relative"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<div
			class="w-full h-1/2 lg:h-24 xl:h-32 absolute top-0 left-0"
			:class="getBackgroundColour(fields.top_background_colour.value)"
		></div>
		<img
			loading="lazy"
			alt="Image"
			class="w-8/12 lg:w-auto max-w-full ml-auto c-image-offset bottom-left block relative z-20"
			:class="
				getBackgroundColour(
					fields.celebration.acf.image_background_colour.value
				)
			"
			:src="fields.celebration.acf.image.sizes.cote_image_size_4_portrait"
		/>
		<div class="text-left lg:w-6/12 pt-10 lg:pt-28 xl:pt-36 mb-10">
			<h5
				class="text-left"
				:class="getTextColour(fields.background_colour.value)"
			>
				{{
					fields.block_pretitle
						? fields.block_pretitle
						: fields.celebration.acf.pretitle
				}}
			</h5>
			<h2
				class="text-left"
				:class="getTextColour(fields.background_colour.value)"
			>
				{{ fields.celebration.acf.title }}
			</h2>
			<p
				class="text-left"
				:class="getTextColour(fields.background_colour.value)"
			>
				{{ fields.celebration.acf.excerpt }}
			</p>
			<router-link
				v-if="!isExternalLink(fields.celebration.acf.button_link)"
				:to="generateUrl(fields.celebration.acf.button_link)"
				class="standard-button"
				:class="
					getBackgroundColour(fields.celebration_button_colour.value)
				"
				>{{ fields.celebration.acf.button_text }}</router-link
			>
			<a
				v-if="isExternalLink(fields.celebration.acf.button_link)"
				:href="generateUrl(fields.celebration.acf.button_link)"
				class="standard-button"
				:class="
					getBackgroundColour(fields.celebration_button_colour.value)
				"
				>{{ fields.celebration.acf.button_text }}</a
			>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CelebrationBlock',
	props: {
		fields: {
			type: Object,
		},
	},
};
</script>

<style scoped></style>
