<template>
	<div
		:id="fields.section_name"
		class="w-full flex flex-col py-10"
		:class="[
			getBackgroundColour(fields.background_colour.value),
			textSideClass,
		]"
	>
		<div
			class="w-full lg:w-6/12 xl:w-7/12 text-left px-5 mb-10 lg:flex flex-col"
			:class="textSpacingClass"
		>
			<h4
				class="text-left lg:w-10/12 2xl:w-8/12"
				:class="getTextColour(fields.background_colour.value)"
			>
				{{ fields.title }}
			</h4>
			<h2
				class="text-left lg:mb-5 lg:w-10/12 2xl:w-8/12"
				:class="getTextColour(fields.background_colour.value)"
			>
				{{ fields.sub_title }}
			</h2>
			<p
				class="wysiwyg text-left w-10/12 lg:w-10/12 2xl:w-8/12"
				:class="getTextColour(fields.background_colour.value)"
				v-html="fields.content"
			></p>
			<div
				v-if="fields.show_button"
				class="lg:w-10/12 2xl:w-8/12 text-left"
			>
				<router-link
					v-if="
						fields.button_link_type == 'internal' &&
						!isExternalLink(fields.button_link)
					"
					:to="generateUrl(fields.button_link)"
					class="standard-button"
					:class="getButtonStyle(fields.button_colour.value)"
					>{{ fields.button_text }}</router-link
				>
				<a
					v-if="fields.button_link_type == 'external'"
					:href="generateUrl(fields.button_link_external)"
					class="standard-button"
					:class="getButtonStyle(fields.button_colour.value)"
					>{{ fields.button_text }}</a
				>
			</div>
		</div>
		<div
			class="w-full lg:w-6/12 relative mb-2 xl:mb-40 flex lg:top-[34px]"
			:class="[imageContainerClass, mobilePaddingClass]"
		>
			<div class="relative">
				<img
					loading="lazy"
					alt="Image"
					:src="
						fields.large_image.sizes[
							getImageSize(
								fields.large_image_orientation.value,
								'large'
							)
						]
					"
					class="max-w-full"
				/>
				<p
					v-if="
						fields.large_image.caption &&
						fields.text_side.value === 'left'
					"
					class="text-xs top-[90px] image-caption caption-left"
				>
					{{ fields.large_image.caption }}
				</p>
				<img
					loading="lazy"
					alt="Image"
					v-if="fields.show_menu_icon"
					:src="fields.menu_icon"
					class="absolute bottom-[50px] w-3/12"
					:class="menuIconClass"
				/>
			</div>
			<div class="relative z-20" :class="smallImagePositionClass()">
				<div class="relative">
					<img
						loading="lazy"
						alt="Image"
						:src="
							fields.small_image.sizes[
								getImageSize(
									fields.small_image_orientation.value,
									'small'
								)
							]
						"
						class="c-image-offset bottom-right max-w-full"
						:class="
							getBackgroundColour(
								fields.small_image_background_colour.value
							)
						"
					/>
					<p
						v-if="
							fields.small_image.caption &&
							fields.text_side.value === 'right'
						"
						class="text-xs top-[60px] lg:top-[unset] lg:bottom-0 image-caption caption-right"
					>
						{{ fields.small_image.caption }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TwoColumnTwoImagesBlock',
	props: {
		fields: {
			type: Object,
		},
	},
	computed: {
		textSideClass() {
			return this.fields.text_side.value === 'left'
				? ' lg:flex-row'
				: ' lg:flex-row-reverse';
		},
		textSpacingClass() {
			return this.fields.text_side.value === 'left'
				? ' lg:pl-28 xl:pl-40 2xl:pl-64 items-start'
				: ' lg:pr-28 xl:pr-40 2xl:pr-64 items-end';
		},
		menuIconClass() {
			return this.fields.text_side.value === 'left'
				? ' right-[50px]'
				: ' left-[50px]';
		},
		imageContainerClass() {
			return this.fields.text_side.value === 'left'
				? ' flex-row-reverse justify-start'
				: ' justify-start';
		},
		mobilePaddingClass() {
			return this.fields.text_side.value === 'left'
				? ' pl-7 lg:pl-0'
				: ' pr-7 lg:pr-0';
		},
	},
	methods: {
		getImageSize(orientation, size) {
			if (size === 'large') {
				return `cote_image_size_5_${orientation}`;
			}
			if (size === 'small') {
				return `cote_image_size_2_${orientation}`;
			}
		},
		smallImagePositionClass() {
			if (this.fields.small_image_orientation.value === 'portrait') {
				return this.fields.text_side.value === 'left'
					? 'mt-20 -mr-36'
					: 'mt-20 -ml-36';
			}
			if (this.fields.small_image_orientation.value === 'landscape') {
				return this.fields.text_side.value === 'left'
					? ' mt-40 lg:mt-52 -mr-40'
					: ' mt-40 lg:mt-52 -ml-40';
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
