<template>
	<section
		v-if="fields.is_active"
		class="overflow-hidd en py-[100px]"
		:class="[
			isLocationRestricted ? 'h -[200px]' : 'h -[200px]',
			getBackgroundColour(fields.background_colour.value),
		]"
	>
		<restaurant-location-selector
			:isLocationRestrictedPromotion="isLocationRestricted"
			:restaurants="brasseries"
			:showPostcodesProp="false"
			parent-classes="w-full lg:w-1/2 2xl:w-2/3 mx-auto px-5 max-w-[730px]"
			:colour="getLocationSelectorColor(fields.background_colour.value)"
			@location-selected="selectLocation($event)"
		>
			<template v-slot:header>
				<h2
					v-if="fields.brasserie_dropdown_heading_text"
					:class="getTextColour(fields.background_colour.value)"
				>
					{{ fields.brasserie_dropdown_heading_text }}
				</h2>
			</template>
		</restaurant-location-selector>
	</section>
</template>

<script>
import RestaurantLocationSelector from '@/components/RestaurantLocationSelector.vue';

export default {
	name: 'BrasserieDropdown',
	data() {
		return {};
	},
	components: {
		RestaurantLocationSelector,
	},
	props: {
		fields: {
			type: Object,
		},
		showLocationDropdownExternalCondition: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			locationSearchString: '',
			showLocationDropdown: false,
		};
	},
	computed: {
		canShowLocationDropdown() {
			return (
				(this.showLocationDropdownExternalCondition &&
					this.showLocationDropdown) ||
				this.locationSearchString !== ''
			);
		},
		isLocationRestricted() {
			return this.fields.brasserie_dropdown_display_type !== 'all';
		},
		brasseries() {
			if (!this.isLocationRestricted) {
				return this.$store.state.restaurants;
			}
			return this.$store.state.restaurants.filter((restaurant) =>
				this.fields.brasserie_selection.includes(restaurant.ID)
			);
		},
	},
	methods: {
		selectLocation(event) {
			const locationUrl = this.getBrasserieLink(event.id);
			const urlPath = this.fields?.url_path ? this.fields?.url_path : '';
			const completeUrl = `${locationUrl}${urlPath}`;
			this.$router.push(completeUrl);
		},
		toggleLocationDropdown() {
			this.showLocationDropdown = !this.showLocationDropdown;
			this.locationSearchString = '';
			this.restaurantsFromLocationSearchSearchedLocation = [];
			this.$emit('locationDropdownToggle', this.showLocationDropdown);
		},
		getStyles(element) {
			let styles = '';
			const isLight = this.colour === 'light';
			if (!Array.isArray(element)) {
				element = [element];
			}
			element.forEach((el) => {
				if (el === 'text') {
					styles += isLight ? ' text-brand' : ' text-cream';
				}
				if (el === 'border') {
					styles += isLight ? ' border-brand' : ' border-cream';
				}
				if (el === 'placeholder') {
					styles += isLight
						? ' placeholder:text-brand'
						: ' placeholder:text-cream';
				}
				if (el === 'chevron') {
					styles += isLight
						? ' border-brand bg-transparent hover:bg-brand text-brand hover:text-cream'
						: ' border-cream bg-transparent hover:bg-cream text-cream hover:text-brand';
				}
				if (el === 'background') {
					styles += isLight ? ' bg-cream' : ' bg-brand';
				}
				if (el === 'item-hover') {
					styles += isLight
						? ' text-brand hover:bg-brand hover:text-cream'
						: ' text-cream hover:bg-cream hover:text-brand';
				}
			});
			return styles;
		},
		getLocationSelectorColor(backgroundColour) {
			if (
				backgroundColour === 'navy' ||
				backgroundColour === 'dark-green'
			) {
				return 'dark';
			}
			if (backgroundColour === 'copper') {
				return 'dark';
			}
			return 'light';
		},
	},

	mounted() {},
};
</script>

<style lang="scss" scoped>
.anchor {
	position: relative;
	top: -150px;
}
</style>
