<template>
	<div class="py-10 bg-noise-pale">
		<div class="w-full" v-if="customer">
			<button
				@click.prevent="accept"
				class="standard-button header-type-newsletter-form-button"
				v-text="buttonText"
				:class="getButtonStyle('navy')"
			/>

			<div class="mt-10">
				<div v-if="showStep2 && step2 === 0">
					<fieldset v-if="showStep2">
						<p>Additional Newsletters</p>

						<div v-if="showCote">
							<label for="newsletter">
								<input
									v-model="newsletter"
									type="checkbox"
									class="hidden"
									id="newsletter"
									name="newsletter"
								/>
								<img
									loading="lazy"
									alt="Image"
									:class="newsletter ? 'hidden' : ''"
									src="@/assets/checkbox-unchecked.svg"
									class="cursor-pointer inline w-5 mr-2"
								/>
								<img
									loading="lazy"
									alt="Image"
									:class="newsletter ? '' : 'hidden'"
									src="@/assets/checkbox-checked.svg"
									class="cursor-pointer inline w-5 mr-2"
								/>
								<span
									>Please unsubscribe from
									<strong>Côte Brasserie</strong>
									newsletters.</span
								>
							</label>
						</div>

						<div v-if="showCah">
							<label for="cah">
								<input
									v-model="cah"
									type="checkbox"
									class="hidden"
									id="cah"
									name="cah"
								/>
								<img
									loading="lazy"
									alt="Image"
									:class="cah ? 'hidden' : ''"
									src="@/assets/checkbox-unchecked.svg"
									class="cursor-pointer inline w-5 mr-2"
								/>
								<img
									loading="lazy"
									alt="Image"
									:class="cah ? '' : 'hidden'"
									src="@/assets/checkbox-checked.svg"
									class="cursor-pointer inline w-5 mr-2"
								/>
								<span
									>Please unsubscribe from
									<strong>Côte at Home</strong>
									newsletters.</span
								>
							</label>
						</div>
						<div v-if="showReviews">
							<label for="reviews">
								<input
									v-model="reviews"
									type="checkbox"
									class="hidden"
									id="reviews"
									name="reviews"
								/>
								<img
									loading="lazy"
									alt="Image"
									:class="reviews ? 'hidden' : ''"
									src="@/assets/checkbox-unchecked.svg"
									class="cursor-pointer inline w-5 mr-2"
								/>
								<img
									loading="lazy"
									alt="Image"
									:class="reviews ? '' : 'hidden'"
									src="@/assets/checkbox-checked.svg"
									class="cursor-pointer inline w-5 mr-2"
								/>
								<span
									>Please unsubscribe from
									<strong>Côte Brasserie</strong> review
									emails.</span
								>
							</label>
						</div>
					</fieldset>
					<button
						@click.prevent="unsubscribeButton"
						class="mt-4 standard-button header-type-newsletter-form-button"
						v-text="`Unsubscribe`"
						:class="getButtonStyle('yellow')"
					/>
				</div>

				<div v-else>
					<span v-html="this.fields.thank_you" />
				</div>
			</div>
		</div>
		<div v-else>
			<span v-html="this.fields.not_found" />
		</div>
	</div>
</template>

<script>
import exponea from '@/plugins/exponea';
import wordpress from '@/plugins/wordpress';

export default {
	props: {
		fields: {
			type: Object,
		},
	},

	data() {
		return {
			customer: null,
			email: '',
			accepted: false,
			newsletter: false,
			cah: false,
			reviews: false,
			step2: 0,
		};
	},

	computed: {
		buttonText() {
			return this.accepted ? 'Saved!' : 'Subscribe Again';
		},

		showCote() {
			return (
				this.customer.newsletter &&
				this.fields.unsubscribe_from !== 'newsletter'
			);
		},
		showCah() {
			return this.customer.cah && this.fields.unsubscribe_from !== 'cah';
		},
		showReviews() {
			return (
				this.customer.receive_review_emails !== false &&
				this.fields.unsubscribe_from !== 'reviews'
			);
		},

		showStep2() {
			return this.showReviews || this.showCah || this.showCote;
		},
	},

	methods: {
		unsubscribeButton() {
			if (this.showCote && this.newsletter && this.customer.newsletter) {
				this.unsubscribeFrom('newsletter');
			}

			if (this.showCah && this.cah && this.customer.cah) {
				this.unsubscribeFrom('cah');
			}

			if (
				this.showReviews &&
				this.reviews &&
				this.customer.receive_review_emails !== false
			) {
				this.unsubscribeFrom('reviews');
			}
			this.step2 = 1;
		},

		unsubscribeFrom(from) {
			if (from === 'reviews') {
				exponea.update({
					email: this.customer.email,
					receive_review_emails: false,
				});

				exponea.track('preferences', {
					email: this.customer.email,
					location: window.location.href,
					receive_review_emails: false,
				});
				return;
			}

			exponea.track('consent', {
				action: 'reject',
				category: from,
				location: window.location.href,
				domain: 'cote.co.uk',
				language: 'en',
				placement: 'Unsubscribe Page',
			});

			exponea.track('unsubscribe', {
				email: this.customer.email,
				company: from === 'cah' ? 'cah' : 'cote',
				location: window.location.href,
			});
		},

		accept() {
			this.accepted = true;

			if (this.fields.unsubscribe_from === 'reviews') {
				expopnea.track('preferences', {
					email: this.customer.email,
					location: window.location.href,
					receive_cah_emails: true,
				});
				return;
			}

			expopnea.track('consent', {
				action: 'accept',
				category: this.fields.unsubscribe_from,
				location: window.location.href,
				domain: 'cote.co.uk',
				language: 'en',
				placement: 'Unsubscribe Page',
				valid_until: 'unlimited',
			});
		},
	},

	async mounted() {
		const { ee } = this.$route.query;

		if (ee) {
			// Convert it to an email address
			this.email = atob(ee).trim().toLowerCase();

			// tell exponea who we've got
			await exponea.identify(this.email);

			// Grab all their details from exponea.
			wordpress
				.exponeaCustomer({ email: this.email })
				.then((r) => r.json())
				.then((resp) => {
					if (resp.success) {
						this.customer = resp.customer;
						this.unsubscribeFrom(this.fields.unsubscribe_from);
					}
				});
		}
	},
};
</script>
