<template>
	<div
		id="faqBlock"
		class="w-full py-10 px-5 lg:px-28 xl:px-40 2xl:px-64 flex flex-col items-center"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<div class="w-full flex flex-col items-center">
			<h2
				class="self-start overflow-hidden text-left"
				:class="getTextColour(fields.background_colour.value)"
			>
				{{ fields.faq_heading_text }}
			</h2>
			<ul class="flex justify-center flex-wrap w-11/12 md:w-10/12 my-14">
				<li
					v-for="tag in faqTags"
					:key="tag.term_id"
					@click="selectTag(tag.term_id)"
					@keypress="selectTag(tag.term_id)"
					:class="
						tag.term_id === currentTag.term_id ? 'bg-brand' : ''
					"
					class="m-2 cursor-pointer border-solid border border-brand border-l w-fit"
				>
					<h5
						class="py-1 px-4 m-0 duration-300 hover:bg-brand text-brand hover:text-cream"
						:class="
							tag.term_id === currentTag.term_id
								? 'text-cream'
								: ''
						"
					>
						{{ tag.name }}
					</h5>
				</li>
			</ul>
			<div class="flex flex-col md:flex-row justify-center w-full">
				<ul
					class="w-11/12 md:w-5/12 overflow-auto md:max-h-96 md:mr-10 mb-10 self-center c-scrollbar"
				>
					<li
						v-for="faq in faqsMatchingCurrentTag"
						:key="faq.ID"
						class="border border-brand border-b md:border-0 p-3 md:p-0"
						@click="selectFaq(faq.ID)"
						@keypress="selectFaq(faq.ID)"
					>
						<p
							class="faq-question font-parisine px-0 md:px-2 md:py-6 text-left cursor-pointer md:border-b md:border-brand md:border-0 md:border-b md:mr-5"
						>
							<span
								class="faq-diamond duration-300"
								:class="
									faq.ID === currentFaq?.ID ? 'bg-brand' : ''
								"
							></span>
							{{ faq.post_title }}
						</p>
						<div
							class="m-0 md:mr-20 text-left text-sm wysiwyg md:hidden overflow-hidden duration-300"
							:class="
								faq.ID === currentFaq?.ID
									? 'max-h-96'
									: 'max-h-0'
							"
							v-html="faq?.acf?.answer"
						></div>
					</li>
				</ul>
				<div
					class="faq-answer w-full md:w-7/12 hidden md:block p-6 pt-1 border border-solid border-brand border-l md:h-96"
				>
					<div
						id="faqAnswer"
						class="m-0 md:mr-20 text-left text-sm wysiwyg"
						v-html="
							currentFaq?.acf?.answer ||
							faqsMatchingCurrentTag[0].acf.answer
						"
					></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FaqsBlock',
	data() {
		return {
			currentTag: {},
			currentFaq: {},
			pseudoTags: [],
			searchValue: '',
		};
	},
	props: {
		fields: {
			type: Object,
		},
	},
	computed: {
		/**
		 * faqTags
		 * @returns { Array } Returns an array of unique tags that are referenced by the selected FAQs
		 */
		faqTags() {
			// Declare the array to be returned
			const theTags = [];

			// For each selected FAQ
			for (const faq of this.faq_selection) {
				const faqTags = faq.faq_tags;
				// If the FAQ has tags
				if (faqTags) {
					// For each faqTag of the selected FAQb
					for (const faqTag of faqTags) {
						// If theTags doesn't already contain the faqTag
						if (
							!theTags.some(
								(tag) => tag.term_id === faqTag.term_id
							)
						) {
							// Add the faqTag to the the theTags array
							theTags.push(faqTag);
						}
					}
				} else if (
					theTags
						.map((tag) => tag.term_id)
						.indexOf('uncategorised') === -1
				) {
					// If the faq has no tags, add an uncategorised tag to the list if it doesn't already exist
					theTags.push({
						term_id: 'uncategorised',
						name: 'Uncategorised',
					});
				}
			}
			// Add the tags before the pseudotags
			return [...theTags, ...this.pseudoTags];
		},
		faq_selection() {
			return this.fields.faq_selection.filter(
				(faq) => faq.post_status === 'publish'
			);
		},
		faqsMatchingCurrentTag() {
			let filteredFaqs = this.faq_selection;
			// If the tag is the search result tag
			if (this.currentTag.term_id === 'searchTag') {
				// Filter the faqs based on the search term
				filteredFaqs = this.filterBySearchTerm();
			} else {
				// The tag is not the search result tag
				// Filter the faqs based on the tag id
				filteredFaqs = this.filterByTag();
			}

			return filteredFaqs;
		},
	},
	methods: {
		selectTag(termId) {
			this.currentTag = this.faqTags.find(
				(tag) => tag.term_id === termId
			);
			this.selectFirstFaq();
		},
		selectFirstTag() {
			this.selectTag(this.faqTags[0].term_id);
		},
		selectFaq(faqId) {
			this.currentFaq = this.faq_selection.find(
				(faq) => faq.ID === faqId
			);
		},
		selectFirstFaq() {
			this.selectFaq(this.faqsMatchingCurrentTag[0].ID);
		},
		createPsuedoTag(id, name) {
			this.pseudoTags.push({ term_id: id, name });
		},
		/**
		 * Creates a mock faq for use in displaying a custom message
		 * @param {int} id id of the pseudofaq
		 * @param {String} title title of the pseudofaq
		 * @param {Object} acf acf fields of the pseudofaq e.g. { answer: "answer" }
		 */
		createPsuedoFaq(id, title, acf) {
			return { id, postTitle: title, acf };
		},
		/**
		 * This method is triggered by the parent view (DefaultView) via refs as the search term comes from the header block
		 * @param {String} searchValue the value being searched for
		 */
		search(searchValue) {
			this.searchValue = searchValue;
			if (this.pseudoTags.find((tag) => tag.term_id === 'searchTag')) {
				// If there is already a search tag, delete it
				// So we don't get duplicates
				const searchTagIndex = this.pseudoTags
					.map((tag) => tag.term_id)
					.indexOf('searchTag');
				this.pseudoTags.splice(searchTagIndex, 1);
			}
			// Create the new search tag with the search value
			this.createPsuedoTag(
				'searchTag',
				`Search results for: "${searchValue}"`
			);
			// Select the tag once it's been created
			this.selectTag('searchTag');
		},
		filterByTag() {
			const filteredFaqs = this.faq_selection.filter((faq) => {
				// Get the tags of the current FAQ
				const faqTags = faq.faq_tags;

				// If the FAQ has no tags and the category selected is 'uncategorised'
				if (!faqTags && this.currentTag.term_id === 'uncategorised') {
					// include the FAQ in the filter
					return true;
				}

				// If there are no tags and the category is not 'uncategorised'
				if (!faqTags) return false;

				// If any of the tags of the current FAQ match the ID of the currently selected term.
				return faqTags.find((tag) => {
					// If the current tag is set
					if (typeof this.currentTag.term_id !== 'undefined') {
						// Only return true if the faq's tags match the current tag
						return tag.term_id === this.currentTag.term_id;
					}
					return true; // Else return true for all faqs
				});
			});
			// If faqs are found
			if (filteredFaqs.length > 0) {
				return filteredFaqs;
			}
			return [
				this.createPsuedoFaq(-1, 'No FAQs Found', {
					answer: 'No FAQs found for this tag!',
				}),
			];
		},
		filterBySearchTerm() {
			const filteredFaqs = this.faq_selection.filter((faq) => {
				// Selecting a subset of searchable props from the faq because we don't want to search all the metadata
				// Uses some funny syntax, look here https://medium.com/@captaindaylight/get-a-subset-of-an-object-9896148b9c72
				// eslint-disable-next-line camelcase
				const searchableSubset = (({ acf, postTitle }) => ({
					acf,
					postTitle,
				}))(faq);
				// Converting the object into a string
				const searchableSubsetString =
					JSON.stringify(searchableSubset).toLowerCase();
				// Return the FAQ if there is a partial match with the search value
				return (
					searchableSubsetString.search(
						this.searchValue.toLowerCase()
					) > 0
				);
			});

			if (filteredFaqs.length > 0) {
				return filteredFaqs;
			}
			return [
				this.createPsuedoFaq(-1, 'No FAQs Found', {
					answer: `No FAQs found related to "${this.searchValue}"`,
				}),
			];
		},
	},
	mounted() {
		this.selectFirstTag();
	},
};
</script>

<style lang="scss">
.faq-diamond {
	width: 1vw;
	max-width: 20px;
	min-width: 10px;
	height: 1vw;
	max-height: 20px;
	min-height: 10px;
	display: inline-block;
	margin: 0 5px 0 10px;
	position: relative;
	transform: scaleY(0.5) rotate(45deg) translateY(50%);
	border: 1px solid #0a2036;
}

.faq.selected > .faq-diamond {
	background-color: #0a2036;
}

.faq-question {
	font-size: 0.875rem;
}

#faqAnswer * {
	font-size: 0.875rem !important;
}

.c-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: #0a2036 #d7d7cb;

	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #0a2036;
		border-radius: 0px;
	}
}

.wysiwyg > p {
	font-size: 16px !important;
}
</style>
