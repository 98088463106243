<template>
	<div
		:id="fields?.section_name"
		class="mood-board-block w-full relative"
		:class="
			getBackgroundColour(fields?.background_colour?.value ?? 'cream')
		"
	>
		<slot></slot>
		<div
			v-for="(group, i) in fields.image_group"
			:key="i"
			class="w-full relative top-[-500px]"
		>
			<!--   Three images - small one left side   -->
			<div
				v-if="group.number_of_images.value === '3-left'"
				class="flex justify-between items-end pb-32 relative"
			>
				<div class="flex justify-start items-end relative">
					<div>
						<scroll-parallax :speed="0.02">
							<p
								class="text-xs image-caption caption-right top-[35%]"
							>
								{{ group['3l_image_left_caption'] }}
							</p>
							<img
								loading="lazy"
								alt="Image"
								:src="
									group['3l_image_left'].sizes
										.cote_image_size_4_portrait
								"
								class="max-w-full"
							/>
						</scroll-parallax>
					</div>
					<div class="relative z-20 -ml-20 -mb-20">
						<p class="text-xs image-caption caption-right bottom-0">
							{{ group['3l_image_left_small_caption'] }}
						</p>
						<img
							loading="lazy"
							alt="Image"
							:src="
								group['3l_image_left_small'].sizes
									.cote_image_size_3_landscape
							"
							class="max-w-full c-image-offset bottom-left"
							:class="
								getBackgroundColour(
									group[
										'3l_image_left_small_background_offset_colour'
									].value
								)
							"
						/>
					</div>
				</div>
				<scroll-parallax :speed="0.02">
					<p class="text-xs image-caption caption-left top-[25%]">
						{{ group['3l_image_right_caption'] }}
					</p>
					<img
						loading="lazy"
						alt="Image"
						:src="
							group['3l_image_right'].sizes
								.cote_image_size_2_landscape
						"
						class="lg:max-w-[75%] xl:max-w-full mb-20 lg:mr-20 xl:mr-32"
					/>
				</scroll-parallax>
				<div class="rotate-90 absolute z-20 left-[80%] top-[10%]">
					<div class="c-diamond"></div>
				</div>
			</div>
			<!--   Two images   -->
			<div
				v-if="group.number_of_images.value === '2'"
				class="flex justify-center pb-32"
			>
				<div class="flex justify-start items-end relative">
					<div class="relative z-20 -mr-20 -mb-20">
						<p class="text-xs image-caption caption-left bottom-0">
							{{ group['2_image_small_caption'] }}
						</p>
						<img
							loading="lazy"
							alt="Image"
							:src="
								group['2_image_small'].sizes
									.cote_image_size_2_landscape
							"
							class="max-w-full c-image-offset bottom-right"
							:class="
								getBackgroundColour(
									group[
										'2_image_small_background_offset_colour'
									].value
								)
							"
						/>
					</div>
					<div>
						<scroll-parallax :speed="0.02">
							<p
								class="text-xs image-caption caption-right bottom-0"
							>
								{{ group['2_image_left_caption'] }}
							</p>
							<img
								loading="lazy"
								alt="Image"
								:src="
									group['2_image_left'].sizes
										.cote_image_size_4_portrait
								"
								class="max-w-full"
							/>
						</scroll-parallax>
					</div>
				</div>
			</div>
			<!--   Three images - small one right side   -->
			<div
				v-if="group.number_of_images.value === '3-right'"
				class="flex justify-between items-end pb-32 relative"
			>
				<div class="rotate-90 absolute z-20 left-[-5%] top-[-40%]">
					<div class="c-diamond"></div>
				</div>
				<scroll-parallax :speed="0.02">
					<p class="text-xs image-caption caption-right bottom-[25%]">
						{{ group['3r_image_right_caption'] }}
					</p>
					<img
						loading="lazy"
						alt="Image"
						:src="
							group['3r_image_right'].sizes
								.cote_image_size_4_landscape
						"
						class="max-w-full mb-20"
					/>
				</scroll-parallax>
				<div class="flex justify-start items-end relative mb-72">
					<div class="relative z-20 -mb-20 -mr-20">
						<p class="text-xs image-caption caption-left bottom-0">
							{{ group['3r_image_left_small_caption'] }}
						</p>
						<img
							loading="lazy"
							alt="Image"
							:src="
								group['3r_image_left_small'].sizes
									.cote_image_size_3_landscape
							"
							class="max-w-full c-image-offset bottom-right"
							:class="
								getBackgroundColour(
									group[
										'3r_image_left_small_background_offset_colour'
									].value
								)
							"
						/>
					</div>
					<div>
						<scroll-parallax :speed="0.02">
							<p
								class="text-xs image-caption caption-left top-[35%]"
							>
								{{ group['3r_image_left_caption'] }}
							</p>
							<img
								loading="lazy"
								alt="Image"
								:src="
									group['3r_image_left'].sizes
										.cote_image_size_4_portrait
								"
								class="max-w-full ml-auto"
							/>
						</scroll-parallax>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';

export default {
	name: 'MoodBoardBlock',
	components: {
		ScrollParallax,
	},
	props: {
		fields: {
			type: Object,
		},
	},
	methods: {
		getImageSize(orientation, size) {
			if (size === 'large') {
				return `cote_image_size_5_${orientation}`;
			}
			if (size === 'small') {
				return `cote_image_size_2_${orientation}`;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.c-diamond {
	margin-left: 30px;
	width: 550px;
	height: 550px;
	background: transparent;
	border: 3px solid #82898f;
	transform: rotateX(45deg) rotateZ(45deg);
}
</style>
