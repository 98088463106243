<template>
	<div
		:id="fields.section_name"
		class="w-full pt-0 pb-14 relative"
		:class="[
			getBackgroundColour(fields.background_colour.value),
			fields.layout_type.value === 'image' ? 'lg:pt-18' : 'lg:pt-32',
		]"
	>
		<div
			class="hidden lg:block w-full h-72 absolute top-0 left-0"
			:class="getBackgroundColour(fields.top_background_colour.value)"
		></div>
		<div
			class="relative z-20 flex flex-col justify-end items-end lg:h-auto"
			:class="[
				fields.layout_type.value === 'image'
					? 'lg:w-8/12 mx-auto'
					: 'h-[400px] lg:pr-28 xl:pr-40 2xl:pr-64',
			]"
		>
			<img
				loading="lazy"
				alt="Image"
				v-if="mediaType !== 'video'"
				:src="mediaUrl"
				class="hidden lg:block w-full relative z-20"
			/>
			<img
				loading="lazy"
				alt="Image"
				v-if="mediaType !== 'video'"
				:src="mediaUrlMobile"
				class="block lg:hidden w-full relative z-20"
			/>

			<lazy-component>
				<video
					v-if="mediaType === 'video'"
					@click="toggleVideo('open')"
					:src="mediaUrl"
					class="hidden lg:block w-full relative z-20"
					autoplay
					muted
					loop
					playsinline
				/>
				<img
					loading="lazy"
					alt="Image"
					v-if="
						mediaType === 'video' &&
						mediaUrlMobile.endsWith('.webp')
					"
					@click="toggleVideo('open')"
					:src="mediaUrlMobile"
					class="block lg:hidden w-full relative z-20"
					autoplay
					muted
					loop
				/>
				<video
					v-else-if="mediaType === 'video'"
					:src="mediaUrlMobile"
					class="block lg:hidden w-full h-full absolute top-0 left-0 object-cover z-20"
					autoplay
					muted
					loop
					playsinline
				/>
			</lazy-component>

			<div
				v-if="fields.layout_type.value === 'text'"
				class="absolute px-5 lg:px-28 xl:px-40 2xl:px-64 left-0 top-[10%] lg:top-[10%] 2xl:top-[15%] text-left z-20"
			>
				<h4 class="text-cream text-left lg:mb-5 2xl:mb-10">
					{{ fields.small_text }}
				</h4>
				<h1
					class="text-cream text-left"
					v-html="fields.large_text"
				></h1>
				<router-link
					v-if="
						fields.show_button_large_image &&
						fields.button_link_type == 'internal' &&
						!isExternalLink(fields.button_link)
					"
					:to="generateUrl(fields.button_link)"
					class="standard-button"
					:class="getButtonStyle(fields.button_colour.value)"
					>{{ fields.button_text }}</router-link
				>
				<a
					v-if="
						fields.show_button_large_image &&
						fields.button_link_type == 'external' &&
						isExternalLink(fields.button_link_external)
					"
					:href="generateUrl(fields.button_link_external)"
					class="standard-button"
					:class="getButtonStyle(fields.button_colour.value)"
					>{{ fields.button_text }}</a
				>
			</div>
			<div
				v-if="fields.layout_type.value === 'text'"
				class="hidden lg:block w-6/12 lg:w-4/12 xl:w-auto mr-10 lg:-mr-16 xl:-mr-28 2xl:-mr-40 mt-20 lg:-mt-24 xl:-mt-40 z-20 relative"
			>
				<img
					loading="lazy"
					alt="Image"
					v-if="fields.layout_type.value === 'text'"
					:src="fields.small_image.sizes.cote_image_size_3_landscape"
					class="c-image-offset bottom-left max-w-full"
					:class="
						getBackgroundColour(
							fields.small_image_background_colour.value
						)
					"
				/>
				<p
					v-if="fields.small_image.caption"
					class="bottom-[0px] image-caption caption-right"
					:class="getTextColour(fields.background_colour.value)"
				>
					{{ fields.small_image.caption }}
				</p>
			</div>
			<vertical-lines
				v-if="fields.show_lines"
				:lines="12"
				:border-width="'thick'"
				class="absolute hidden lg:flex w-2/12 lg:h-[65%] xl:h-[65%] right-0 top-40"
			></vertical-lines>
			<vertical-lines
				v-if="fields.show_lines"
				:lines="20"
				:border-width="'thin'"
				class="absolute flex lg:hidden w-6/12 h-60 left-0 -bottom-[10%] md:-bottom-[20%]"
			></vertical-lines>
		</div>
		<div
			v-if="fields.layout_type.value === 'text'"
			class="lg:hidden w-6/12 lg:w-4/12 xl:w-auto ml-auto mr-10 -mt-14 z-20 relative"
		>
			<img
				loading="lazy"
				alt="Image"
				v-if="fields.layout_type.value === 'text'"
				:src="fields.small_image.sizes.cote_image_size_3_landscape"
				class="c-image-offset bottom-left max-w-full"
				:class="
					getBackgroundColour(
						fields.small_image_background_colour.value
					)
				"
			/>
			<p
				v-if="fields.small_image.caption"
				class="bottom-[0px] image-caption caption-right"
				:class="getTextColour(fields.background_colour.value)"
			>
				{{ fields.small_image.caption }}
			</p>
		</div>
		<div v-if="fields.layout_type.value === 'text'" class="ml-5 text-left">
			<p
				class="text-cream lg:px-28 xl:px-40 2xl:px-64 text-left w-10/12 lg:w-7/12 xl:w-6/12"
				:class="getTextColour(fields.background_colour.value)"
				v-html="fields.text_under_main_image"
			></p>
			<router-link
				v-if="
					fields.show_button_text &&
					fields.text_button_link_type == 'internal' &&
					!isExternalLink(fields.text_button_link)
				"
				:to="generateUrl(fields.text_button_link)"
				class="standard-button lg:ml-28 xl:ml-40 2xl:ml-64"
				:class="getButtonStyle(fields.text_button_colour.value)"
				>{{ fields.text_button_text }}</router-link
			>
			<a
				v-if="
					fields.show_button_text &&
					fields.text_button_link_type == 'external' &&
					isExternalLink(fields.text_button_link_external)
				"
				:href="generateUrl(fields.text_button_link_external)"
				class="standard-button lg:ml-28 xl:ml-40 2xl:ml-64"
				:class="getButtonStyle(fields.text_button_colour.value)"
				>{{ fields.text_button_text }}</a
			>
		</div>

		<div
			ref="videoContainer"
			class="hidden fixed top-0 left-0 w-full h-screen z-70 bg-black"
		>
			<x-icon
				class="text-cream w-8 lg:w-10 absolute top-10 right-10 z-70 cursor-pointer"
				@click="toggleVideo('close')"
			></x-icon>
			<video
				v-if="mediaType === 'video'"
				:src="mediaUrl"
				class="w-10/12 m-auto absolute absolute-center z-20"
				controls
				autoplay
				muted
				loop
				playsinline
			/>
		</div>
	</div>
</template>

<script>
import { XIcon } from '@heroicons/vue/outline';
import VerticalLines from '@/components/VerticalLines.vue';

export default {
	name: 'LargeImageAndTextBlock',
	components: {
		VerticalLines,
		XIcon,
	},
	props: {
		fields: {
			type: Object,
		},
	},
	computed: {
		mediaType() {
			return this.fields.video_media_type.value;
		},
		mediaUrl() {
			return this.fields.video_media_type.value === 'image'
				? this.fields.video_image
				: this.fields.video_video;
		},
		mediaUrlMobile() {
			return this.fields.video_media_type.value === 'image'
				? this.fields.video_image_mobile
				: this.fields.video_video_mobile;
		},
	},
	methods: {
		toggleVideo(type) {
			if (type === 'open') {
				this.$refs.videoContainer.classList.remove('hidden');
			}
			if (type === 'close') {
				this.$refs.videoContainer.classList.add('hidden');
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
