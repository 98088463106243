<template>
	<div
		:id="fields.section_name"
		class="py-10 relative"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<div
			class="w-full h-1/2 absolute top-1/2 left-0"
			:class="getBackgroundColour(fields.bottom_background_colour.value)"
		></div>
		<div
			class="w-11/12 lg:w-6/12 xl:5/12 2xl:w-5/12 mx-auto px-10 lg:px-20 py-10 relative z-10 shadow-lg"
			:class="getBackgroundColour(fields.block_background_colour.value)"
		>
			<h3 :class="getTextColour(fields.background_colour.value)">
				{{ fields.label }}
			</h3>
			<p
				:class="getTextColour(fields.background_colour.value)"
				v-html="fields.content"
			></p>
			<router-link
				v-if="
					!isExternalLink(fields.button_link) &&
					!fields.button_opens_chat
				"
				:to="generateUrl(fields.button_link)"
				class="standard-button"
				:class="getButtonStyle(fields.button_colour.value)"
				>{{ fields.button_text }}</router-link
			>
			<a
				v-if="
					isExternalLink(fields.button_link) &&
					!fields.button_opens_chat
				"
				:href="generateUrl(fields.button_link)"
				class="standard-button"
				:class="getButtonStyle(fields.button_colour.value)"
				>{{ fields.button_text }}</a
			>
			<button
				v-if="fields.button_opens_chat"
				type="button"
				class="standard-button"
				:class="getButtonStyle(fields.button_colour.value)"
				@click="requestLiveChatPopup"
			>
				{{ fields.button_text }}
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CallToActionBlock',
	props: {
		fields: {
			type: Object,
		},
	},
};
</script>

<style scoped></style>
