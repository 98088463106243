<template>
	<div
		class="w-full px-5 lg:px-28 xl:px-40 2xl:px-64 py-4 overflow-x-scroll c-hide-scrollbar"
		:class="[
			getBackgroundColour(fields.background_colour.value),
			scrolledClasses,
		]"
	>
		<div class="flex justify-start lg:justify-center items-center">
			<h6
				v-for="(item, i) in fields.navigation_items"
				:key="i"
				class="bg-transparent border border-brand py-1 px-2 mt-0 mx-2 text-brand hover:text-cream hover:bg-brand cursor-pointer whitespace-nowrap"
				:class="{
					'!bg-brand text-cream':
						item.name.toLowerCase().replace(' ', '_') ===
						activeSection,
				}"
				@click="scrollToSection(item.name)"
			>
				{{ item.name }}
			</h6>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AnchorNavigationBlock',
	props: {
		fields: {
			type: Object,
		},
	},
	data() {
		return {
			activeSectionIndex: null,
			scrollHeight: 0,
			sections: [],
		};
	},
	computed: {
		activeSection() {
			return this.sections.find((s) => s.active === true)?.name;
		},
		scrolledClasses() {
			return {
				'fixed top-[64px] xl:top-[80px] left-0 z-40':
					this.scrollHeight > 5,
			};
		},
	},
	methods: {
		getSections() {
			const sectionNames = this.fields.navigation_items.map(
				(item) => item.name
			);
			this.sections = [];
			sectionNames.forEach((name) => {
				this.sections.push({
					id: name,
					name: name.toLowerCase().replace(' ', '_'),
					active: false,
					offsetTop: null,
				});
			});
		},
		scrollToSection(name) {
			this.sections.forEach((s) => {
				s.active = false;
				if (s.name === name.toLowerCase().replace(' ', '_')) {
					s.active = true;
				}
			});
			this.$emit('sectionChange', name);
		},
		getScrollHeight() {
			this.scrollHeight = window.scrollY;

			// figuring out how far down the page each section starts and where
			// the current scroll position is to determine which section we are in
			this.activeSectionIndex = null;

			this.sections.forEach((s, i) => {
				this.sections[i].offsetTop =
					document.getElementById(s.id).offsetTop - 150;
				this.sections[i].active = false;

				if (this.scrollHeight >= this.sections[i].offsetTop) {
					this.activeSectionIndex = i;
				}
			});

			if (this.activeSectionIndex != null) {
				this.sections[this.activeSectionIndex].active = true;
			}
		},
	},
	created() {
		this.getSections();
		document.addEventListener('scroll', this.getScrollHeight);
	},
	beforeUnmount() {
		document.removeEventListener('scroll', this.getScrollHeight);
	},
};
</script>

<style scoped></style>
