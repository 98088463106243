<template>
	<div v-if="showMessage" class="my-4 bg-brand text-white p-10">
		<h2 class="text-white my-0">{{ message.title }}</h2>
		<p>{{ message.message.replace(/<\/?[^>]+(>|$)/g, '') }}</p>
	</div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
	name: 'BookingMessage',
	computed: {
		showMessage() {
			const selectedDateStartInSeconds = DateTime.fromJSDate(
				this.bookingData.date
			)
				.set({
					hour: 0,
					minute: 0,
					second: 0,
				})
				.toSeconds();

			if (
				selectedDateStartInSeconds < this.message.start ||
				selectedDateStartInSeconds > this.message.end
			) {
				return false;
			}

			if (this.message.guests > this.bookingData.guests.value) {
				return false;
			}

			if (this.message.for === 'exclude') {
				if (
					this.message.restaurants.includes(
						this.bookingData.location.ID
					)
				)
					return false;
			}

			if (this.message.for === 'only') {
				if (
					!this.message.restaurants.includes(
						this.bookingData.location.ID
					)
				)
					return false;
			}

			return true;
		},
	},
	props: {
		bookingData: {
			type: Object,
		},
		message: {
			type: Object,
		},
	},
	methods: {},
};
</script>
