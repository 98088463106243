<template>
	<div :class="classes">
		<div class="card-form">
			<label for="billingName" class="sr-only">Name on Card</label>
			<input
				id="billingName"
				v-model="billingName"
				autocomplete="off"
				type="text"
				placeholder="Name on card"
				class="w-full bg-cream border border-brand h-[56px] px-3 py-1.5 mb-4 font-parisine font-normal text-brand placeholder:font-parisine placeholder:font-normal placeholder:text-brand/70 placeholder:text-sm"
			/>
			<div ref="card" class="card-container"></div>
			<div class="error" v-if="this.hasCardErrors">
				<p class="error-message my-2 smaller">
					{{ cardError.message }}
				</p>
			</div>
			<button
				@click.prevent="submit"
				class="standard-button py-5 bg-brand text-cream w-full mt-3"
				v-if="isCardValid && !loading"
			>
				Confirm Booking
			</button>
			<div
				class="card-form-overlay"
				v-if="hasCompletedCardDetails || loading"
			>
				<div class="loading-spinner" v-if="loading"></div>
				<div
					class="card-confirmed"
					v-if="!loading && hasCompletedCardDetails"
				>
					<h4 class="text-white m-0 p-3 bg-brand">
						<span v-if="this.hasCardErrors">
							Card verification unsuccsesful
						</span>
						<span v-else>
							{{ resultMessage }}
						</span>
					</h4>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import store from '../../../store/store';

let stripe = false;
let elements = false;
let card;

checkForStripeKey();

function checkForStripeKey() {
	if (store.state.bookingConfig.stripePublishableKey) {
		// We have a stripe key, initiate Stripe
		stripe = Stripe(store.state.bookingConfig.stripePublishableKey);
		elements = stripe.elements();
	} else {
		setTimeout(checkForStripeKey, 100);
	}
}

export default {
	props: {
		classes: {
			type: String,
		},
		resultMessage: {
			type: String,
		},
		bookingData: {
			type: Object,
		},
	},
	data() {
		return {
			stripeIsReady: false,
			elements: null,
			stripe: null,
			card: null,
			isCardValid: false,
			hasCompletedCardDetails: false,
			tokenData: null,
			hasCardErrors: false,
			cardError: null,
			stripeIntent: null,
			stripePaymentMethodID: null,
			billingName: '',
			loading: false,
			style: {
				base: {
					border: '1px solid #D8D8D8',
					borderRadius: '4px',
					color: '#000',
					'::placeholder': {
						color: '#bababa',
					},
				},
			},
		};
	},
	computed: {
		/**
		 * Check's billing name is longer than 2 chars, simple verification
		 */
		billingNameCompleted() {
			return this.billingName.length > 2;
		},
	},
	watch: {
		/**
		 * Watches the billingName field
		 */
		billingName() {
			if (!this.stripeIsReady) {
				this.billingName = '';
				return;
			}

			if (this.billingNameCompleted) {
				// If billing name is completed, enable the card field
				card.update({ disabled: false });
			}
		},
	},

	// Create stripe SDK instance when mounted
	async mounted() {
		await this.checkIfStripeIsReady();
		if (this.stripeIsReady) {
			card.on('change', (event) => {
				this.isCardValid =
					event.complete &&
					event.error == null &&
					this.billingNameCompleted;
			});
		}
	},
	beforeUnmount() {
		card.destroy();
	},
	methods: {
		async checkIfStripeIsReady() {
			if (stripe && elements) {
				card = elements.create('card', {
					style: this.style,
					disabled: true,
				});
				card.mount(this.$refs.card);
				this.stripeIsReady = true;
				return true;
			}
			setTimeout(this.checkIfStripeIsReady, 100);
		},
		async handleSecurePayment(data) {
			const { connectedAccountPk, clientSecret, paymentMethod } = data;
			this.loading = true;
			// During the 3ds process we need to create a new instance of stripe using the api-key from OT 424 response for make reservation
			stripe = Stripe(connectedAccountPk);
			await stripe.confirmCardSetup(clientSecret).then((result) => {
				if (result.error) {
					this.cardError = result.error;
					this.hasCardErrors = true;
					this.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
					this.loading = false;
					return;
				}
				this.setupIntent = result.setupIntent.id;
			});
			stripe = Stripe(store.state.bookingConfig.stripePublishableKey);
			this.$emit('on-payment', {
				paymentMethod,
				setupIntent: this.setupIntent,
				...this.tokenData,
			});
		},
		async getToken() {
			this.loading = true;
			try {
				const { token, error } = await stripe.createToken(card);
				if (error) {
					this.hasCardErrors = true;
					this.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
					if (error.message.includes('test card')) {
						this.$emit('on-reservation-error', {
							...error,
							message:
								'Wrong credit card details entered, please try again or contact the restaurant to make a booking',
						});
					} else if (
						error.message.includes('CreditCardTokenSCARequired')
					) {
						this.$emit('on-reservation-error', {
							...error,
							message: 'The card security verification failed.',
						});
					} else {
						this.$emit('on-reservation-error', error);
					}
					throw new Error(error);
				}
				this.isCardValid = true;
				return token;
			} catch (error) {
				this.hasCardErrors = true;
				this.$forceUpdate(); // Forcing the DOM to update so the Stripe Element can update.
				throw new Error(error.message);
			}
		},
		async processPayment() {
			try {
				const token = await this.getToken();
				this.tokenData = token;
				this.$emit('on-payment', token);
			} catch (error) {
				this.isCardValid = false;
				this.hasCardErrors = true;
				this.$forceUpdate();
				throw new Error('Invalid payment');
			}
		},
		async submit() {
			this.hasCompletedCardDetails = true;
			try {
				await this.processPayment();
			} catch (error) {
				throw new Error('Invalid payment, please try again');
			}
		},
	},
};
</script>

<style scoped lang="scss">
.card-container {
	padding: 20px 10px;
	border: 1px solid rgb(10 32 54);
	position: relative;
}

.card-form {
	position: relative;
}

.card-form-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(215, 215, 203, 0.9);
}

.card-confirmed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

@keyframes spinner {
	0% {
		transform: translate3d(-50%, -50%, 0) rotate(0deg);
	}
	100% {
		transform: translate3d(-50%, -50%, 0) rotate(360deg);
	}
}
.loading-spinner {
	// The height here is just for demo purposes
	opacity: 1;
	position: absolute;
	transition: opacity linear 0.1s;
	width: 100%;
	height: 100%;
	&::before {
		animation: 2s linear infinite spinner;
		border: solid 3px #eee;
		border-bottom-color: #f1b434;
		border-radius: 50%;
		content: '';
		height: 40px;
		left: 50%;
		opacity: inherit;
		position: absolute;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		transform-origin: center;
		width: 40px;
		will-change: transform;
	}

	&__text {
		position: fixed;
		left: 50%;
		transform: translateX(-50%);
		top: 50vh;
	}
}

.error-message {
	color: red;
}
</style>
