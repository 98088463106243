<template>
	<div
		class="w-full py-10"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<marquee-text
			:repeat="6"
			:duration="getMarqueeScrollDuration(fields.text)"
			class="text-grey scrolling-text overflow-hidden"
			>{{ fields.text }}&nbsp;</marquee-text
		>
	</div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component';

export default {
	name: 'LargeTextBlock',
	components: {
		MarqueeText,
	},
	props: {
		fields: {
			type: Object,
		},
	},
};
</script>

<style scoped></style>
