<template>
	<div
		:id="fields?.section_name"
		class="py-10 relative"
		:class="getBackgroundColour(fields.background_colour.value)"
	>
		<div
			class="w-full h-1/2 absolute top-1/2 left-0"
			:class="getBackgroundColour(fields.bottom_background_colour.value)"
		></div>
		<div
			class="mx-5 lg:mx-28 xl:mx-40 2xl:mx-64 mx-auto p-5 lg:p-10 flex flex-col lg:flex-row justify-between items-center lg:items-start relative shadow-lg"
			:class="getBackgroundColour(fields.block_background_colour.value)"
		>
			<div
				class="image-container w-full lg:w-6/12 mx-auto relative z-10"
				v-bind:style="{
					backgroundImage:
						'url(' +
						fields.image.sizes.cote_image_size_4_landscape +
						')',
				}"
			></div>
			<div class="text-left lg:w-1/2 lg:pl-5 xl:pl-14 relative z-10">
				<img
					loading="lazy"
					alt="Image"
					v-if="fields.show_menu_highlight_icon"
					:src="fields.menu_highlight_icon.sizes.thumbnail"
					class="two-column-centred-menu-highlight-icon w-20 md:w-20 lg:w-36 absolute top-[15px] -right-[30px] md:top-[15px] lg:top-0 lg:-right-10 z-30"
				/>
				<h4
					:class="getTextColour(fields.block_background_colour.value)"
				>
					{{ fields.title }}
				</h4>
				<h2
					:class="[
						getTextColour(fields.block_background_colour.value),
						{ 'w-10/12': fields.show_menu_highlight_icon },
					]"
				>
					{{ fields.sub_title }}
				</h2>
				<p
					class="wysiwyg"
					:class="[
						getTextColour(fields.block_background_colour.value),
						{ 'lg:w-10/12': fields.show_menu_highlight_icon },
					]"
					v-html="fields.content"
				></p>
				<div
					class="w-full flex flex-col lg:flex-row justify-between items-start"
				>
					<router-link
						v-if="
							fields.show_button &&
							fields.button_link_type == 'internal' &&
							!isExternalLink(fields.button_link)
						"
						:to="generateUrl(fields.button_link)"
						class="standard-button w-full lg:w-1/2 lg:mr-1 lg:px-1"
						:class="getButtonStyle(fields.button_colour.value)"
						>{{ fields.button_text }}</router-link
					>
					<a
						v-if="
							fields.show_button &&
							fields.button_link_type == 'external'
						"
						:href="generateUrl(fields.button_link_external)"
						class="standard-button w-full lg:w-1/2 lg:mr-1 lg:px-1"
						:class="getButtonStyle(fields.button_colour.value)"
						>{{ fields.button_text }}</a
					>
					<router-link
						v-if="
							fields.show_second_button &&
							fields.second_button_link_type == 'internal' &&
							!isExternalLink(fields.second_button_link)
						"
						:to="generateUrl(fields.second_button_link)"
						class="standard-button w-full lg:w-1/2 lg:ml-1 lg:px-1 mt-4 lg:mt-10 min-w-[170px]"
						:class="
							getButtonStyle(fields.second_button_colour.value)
						"
						>{{ fields.second_button_text }}</router-link
					>
					<a
						v-if="
							fields.show_second_button &&
							fields.second_button_link_type == 'external'
						"
						:href="generateUrl(fields.second_button_link_external)"
						class="standard-button w-full lg:w-1/2 lg:ml-1 lg:px-1 mt-4 lg:mt-10 min-w-[170px]"
						:class="
							getButtonStyle(fields.second_button_colour.value)
						"
						>{{ fields.second_button_text }}</a
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TwoColumnCenteredBlock',
	props: {
		fields: {
			type: Object,
		},
	},
};
</script>

<style scoped>
.image-container {
	height: calc(100vw * 160 / 360);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

@media screen(lg) {
	.image-container {
		max-height: 375px;
	}

	.two-column-centred-menu-highlight-icon {
		transform: translateX(50%);
	}
}
</style>
