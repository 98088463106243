<template>
	<div
		:id="fields.section_name"
		class="w-full"
		:class="[
			getBackgroundColour(fields.background_colour.value),
			getPaddingValue(),
		]"
	>
		<!--  Video/GIF with text overlay  -->
		<div v-if="headerType === 'video'">
			<div class="relative lg:h-auto" :class="{ 'h-[400px]': isVideo }">
				<img
					loading="lazy"
					v-if="headerTypeVideoMediaType !== 'video'"
					:src="headerTypeVideoMediaUrl"
					alt="Image"
					class="hidden lg:block w-full mr-5 lg:pr-28 xl:pr-40 2xl:pr-64 relative z-10"
				/>
				<img
					loading="lazy"
					v-if="headerTypeVideoMediaType !== 'video'"
					:src="headerTypeVideoMediaUrlMobile"
					class="block lg:hidden w-full"
					alt="Image"
				/>
				<video
					loading="lazy"
					preload="none"
					v-if="headerTypeVideoMediaType === 'video'"
					:src="headerTypeVideoMediaUrl"
					class="hidden lg:block w-full mr-5 lg:pr-28 xl:pr-40 2xl:pr-64 relative z-10"
					@click="toggleVideo('open', headerType)"
					@keypress="toggleVideo('open', headerType)"
					ref="headerVideo-video"
					autoplay
					muted
					loop
					playsinline
				/>
				<img
					loading="lazy"
					alt="Image"
					v-if="
						headerTypeVideoMediaType === 'video' &&
						headerTypeVideoMediaUrlMobile.endsWith('.webp')
					"
					:src="headerTypeVideoMediaUrlMobile"
					class="block lg:hidden w-full"
					autoplay
					muted
					loop
				/>
				<video
					loading="lazy"
					preload="none"
					v-else-if="headerTypeVideoMediaType === 'video'"
					:src="headerTypeVideoMediaUrlMobile"
					class="block lg:hidden w-full h-full absolute top-0 left-0 object-cover"
					@click="toggleVideo('open', headerType)"
					@keypress="toggleVideo('open', headerType)"
					ref="headerVideoMobile-video"
					autoplay
					muted
					loop
					playsinline
				/>
				<div
					class="sound-toggle w-10 h-10 cursor-pointer absolute bottom-0 hidden lg:block lg:right-28 xl:right-40 2xl:right-64 z-10 mr-2 mb-2"
					@click="toggleVideoSound(headerType)"
					@keypress="toggleVideoSound(headerType)"
				>
					<img
						alt="Image"
						loading="lazy"
						v-if="
							headerTypeVideoMediaType === 'video' &&
							!headerTypeVideoMediaUrlMobile.endsWith('.webp')
						"
						src="@/assets/speaker-icon.svg"
						class=""
					/>
					<div
						v-if="
							headerVideoMuted &&
							fields.header_type_image_main_media_type === 'video'
						"
						class="crossed w-full h-full absolute top-0 left-0"
					></div>
				</div>
				<vertical-lines
					v-if="fields.header_type_video_show_lines"
					:lines="20"
					class="w-4/12 h-[70%] flex hidden lg:flex mt-16 absolute top-1/2 right-2"
				></vertical-lines>
				<div
					class="absolute header_type_video_text_container left-5 lg:left-28 xl:left-40 2xl:left-40 w-3/5 lg:w-full z-20 pt-10"
				>
					<img
						loading="lazy"
						alt="Image"
						src="@/assets/active_menu.png"
						class="w-6 lg:w-10"
					/>
					<h1
						v-html="
							convertNewLinesToBreaks(
								fields.header_type_video_text
							)
						"
						class="text-white text-left"
					></h1>

					<div
						class="flex flex-col lg:flex-row lg:justify-start items-start lg:items-center"
					>
						<router-link
							v-if="
								fields.header_type_video_show_button &&
								fields.header_type_video_button_link_type ==
									'internal' &&
								!isExternalLink(
									fields.header_type_video_button_link
								)
							"
							:to="
								generateUrl(
									fields.header_type_video_button_link
								)
							"
							class="standard-button w-[180px] lg:w-[230px] lg:px-6 lg:mr-4 whitespace-nowrap"
							:class="
								getButtonStyle(
									fields.header_type_video_button_colour.value
								)
							"
							>{{
								fields.header_type_video_button_text
							}}</router-link
						>
						<a
							v-if="
								fields.header_type_video_show_button &&
								fields.header_type_video_button_link_type ==
									'external'
							"
							:href="
								generateUrl(
									fields.header_type_video_button_link_external
								)
							"
							class="standard-button w-[180px] lg:w-[230px] lg:px-6 lg:mr-4 whitespace-nowrap"
							:class="
								getButtonStyle(
									fields.header_type_video_button_colour.value
								)
							"
							>{{ fields.header_type_video_button_text }}</a
						>
						<a
							v-if="
								fields.header_type_video_show_button &&
								fields.header_type_video_button_link_type ==
									'email' &&
								enquireEmailLink
							"
							:href="enquireEmailLink"
							class="standard-button w-[180px] lg:w-[230px] lg:px-6 lg:mr-4 whitespace-nowrap"
							:class="
								getButtonStyle(
									fields.header_type_video_button_colour.value
								)
							"
							>{{ fields.header_type_video_button_text }}</a
						>
						<router-link
							v-if="
								fields.header_type_video_show_button_two &&
								fields.header_type_video_button_two_link_type ==
									'internal' &&
								!isExternalLink(
									fields.header_type_video_button_link_two
								)
							"
							:to="
								generateUrl(
									fields.header_type_video_button_link_two
								)
							"
							class="standard-button w-[180px] lg:w-[230px] lg:px-6 mt-4 lg:mt-10 whitespace-nowrap"
							:class="
								getButtonStyle(
									fields.header_type_video_button_colour_two
										.value
								)
							"
							>{{
								fields.header_type_video_button_text_two
							}}</router-link
						>
						<a
							v-if="
								fields.header_type_video_show_button_two &&
								fields.header_type_video_button_two_link_type ==
									'external'
							"
							:href="
								generateUrl(
									fields.header_type_video_button_link_two_external
								)
							"
							class="standard-button w-[180px] lg:w-[230px] lg:px-6 mt-4 lg:mt-10 whitespace-nowrap"
							:class="
								getButtonStyle(
									fields.header_type_video_button_colour_two
										.value
								)
							"
							>{{ fields.header_type_video_button_text_two }}</a
						>
					</div>
				</div>
			</div>
			<div
				v-if="fields.header_type_video_include_celebration"
				class="w-full lg:px-28 xl:px-40 2xl:px-64 flex justify-end lg:justify-between -mt-14 lg:-mt-20 xl:-mt-28 relative z-10 pointer-events-none"
			>
				<vertical-lines
					v-if="fields.header_type_video_show_lines"
					:lines="12"
					:border-width="'thin'"
					class="w-6/12 h-32 flex lg:hidden mt-16 -ml-5"
				></vertical-lines>
				<div
					class="hidden lg:block w-6/12 lg:mt-32 xl:mt-40 text-left pointer-events-auto"
				>
					<h4 class="text-left text-cream">
						{{
							fields.header_type_video_celebration_pretitle
								? fields.header_type_video_celebration_pretitle
								: celebration.acf.pretitle
						}}
					</h4>
					<h2 class="text-cream text-left w-9/12">
						{{ celebration.acf.title }}
					</h2>
					<p class="text-cream text-left w-9/12">
						{{ celebration.acf.excerpt }}
					</p>
					<router-link
						v-if="!isExternalLink(celebration.acf.button_link)"
						:to="generateUrl(celebration.acf.button_link)"
						class="standard-button bg-cream"
						:class="
							getButtonStyle(celebration.acf.button_colour.value)
						"
						>{{ celebration.acf.button_text }}</router-link
					>
					<a
						v-if="isExternalLink(celebration.acf.button_link)"
						:href="generateUrl(celebration.acf.button_link)"
						class="standard-button bg-cream"
						:class="
							getButtonStyle(celebration.acf.button_colour.value)
						"
						>{{ celebration.acf.button_text }}</a
					>
				</div>
				<div class="w-6/12 lg:w-4/12 2xl:w-3/12 mr-5 lg:mr-20">
					<img
						loading="lazy"
						alt="Image"
						:src="
							celebration.acf.image.sizes
								.cote_image_size_4_portrait
						"
						class="c-image-offset bottom-left max-w-full"
						:class="
							getBackgroundColour(
								celebration.acf.image_background_colour.value
							)
						"
					/>
				</div>
			</div>
			<div
				v-if="fields.header_type_video_include_celebration"
				class="lg:hidden w-full px-5 py-10 text-left"
			>
				<h5 class="text-cream text-left">
					{{
						fields.header_type_video_celebration_pretitle
							? fields.header_type_video_celebration_pretitle
							: celebration.acf.pretitle
					}}
				</h5>
				<h2 class="text-cream text-left w-9/12">
					{{ celebration.acf.title }}
				</h2>
				<p class="text-cream text-left">
					{{ celebration.acf.excerpt }}
				</p>
				<router-link
					v-if="!isExternalLink(celebration.acf.button_link)"
					:to="generateUrl(celebration.acf.button_link)"
					class="standard-button bg-cream"
					:class="getButtonStyle(celebration.acf.button_colour.value)"
					>{{ celebration.acf.button_text }}</router-link
				>
				<a
					v-if="isExternalLink(celebration.acf.button_link)"
					:href="generateUrl(celebration.acf.button_link)"
					class="standard-button bg-cream"
					:class="getButtonStyle(celebration.acf.button_colour.value)"
					>{{ celebration.acf.button_text }}</a
				>
			</div>
		</div>
		<div
			ref="videoContainer-video"
			class="hidden fixed top-0 left-0 w-full h-screen z-70 bg-black"
		>
			<x-icon
				class="text-cream w-8 lg:w-10 absolute top-10 right-10 z-70 cursor-pointer"
				@click="toggleVideo('close', headerType)"
			></x-icon>
			<video
				loading="lazy"
				preload="none"
				v-if="headerTypeVideoMediaType === 'video'"
				ref="headerVideo-video-popup"
				:src="headerTypeVideoMediaUrl"
				class="w-10/12 m-auto absolute absolute-center z-20"
				muted
				autoplay
				loop
				playsinline
			/>
		</div>
		<!--  Text with image to right and video/GIF below  -->
		<div v-if="headerType === 'image'">
			<div
				class="flex justify-between lg:pt-4 pl-5 pb-10 lg:pb-0 lg:pl-28 xl:pl-40 2xl:pl-64 lg:pr-28 xl:pr-40 2xl:pr-64 relative z-30"
			>
				<div class="w-3/5 relative">
					<h4 class="text-left">
						{{ fields.header_type_image_small_text }}
					</h4>
					<h1
						class="text-brand text-left w-9/12"
						v-html="fields.header_type_image_large_text"
					></h1>
					<img
						alt="Image"
						loading="lazy"
						v-if="fields.header_type_image_show_image_behind_title"
						:src="fields.header_type_image_image_behind_title"
						class="max-w-full hidden lg:block absolute left-1/2 top-10"
					/>
				</div>
				<div
					v-if="fields.header_type_image_show_image_behind_title"
					class="lg:hidden relative w-2/5"
				>
					<img
						alt="Image"
						loading="lazy"
						:src="fields.header_type_image_image_behind_title"
						class="absolute top-[50%] right-5 w-full max-w-[200px]"
						style="transform: translateY(-50%)"
					/>
				</div>
			</div>
			<div class="relative lg:mt-16 pb-10 lg:pb-0 mr-5 lg:mr-0">
				<vertical-lines
					:lines="12"
					:border-width="'thick'"
					class="w-2/12 h-[70%] flex hidden lg:flex mt-16 absolute lg:top-18 xl:top-20 2xl:top-28 left-0"
				></vertical-lines>
				<img
					alt="Image"
					loading="lazy"
					v-if="fields.header_type_image_main_media_type == 'image'"
					:src="fields.header_type_image_main_image"
					class="w-full lg:pl-28 xl:pl-40 2xl:pl-64 relative z-20"
				/>
				<img
					alt="Image"
					loading="lazy"
					v-if="
						fields.header_type_image_main_media_type == 'video' &&
						fields.header_type_image_video_mobile.endsWith('.webp')
					"
					:src="fields.header_type_image_video_mobile"
					class="block lg:hidden w-full"
					autoplay
					muted
					loop
				/>
				<video
					loading="lazy"
					preload="none"
					v-else-if="
						fields.header_type_image_main_media_type == 'video'
					"
					:src="fields.header_type_image_video_mobile"
					ref="headerVideoMobile-image"
					class="block lg:hidden w-full lg:pl-28 xl:pl-40 2xl:pl-64 relative z-20"
					autoplay
					muted
					loop
					playsinline
				/>
				<video
					loading="lazy"
					preload="none"
					v-if="fields.header_type_image_main_media_type == 'video'"
					:src="fields.header_type_image_video_desktop"
					ref="headerVideo-image"
					@click="toggleVideo('open', headerType)"
					@keypress="toggleVideo('open', headerType)"
					class="hidden lg:block w-full lg:pl-28 xl:pl-40 2xl:pl-64 relative z-20"
					autoplay
					muted
					loop
					playsinline
				/>
				<img
					alt="Image"
					loading="lazy"
					v-if="fields.header_type_image_show_small_top_image"
					:src="
						fields.header_type_image_small_top_image.sizes
							.cote_image_size_4_portrait
					"
					class="hidden lg:block absolute top-0 z-30 right-[40px] xl:right-[163px] max-w-full c-image-offset bottom-left"
					:class="
						getBackgroundColour(
							fields
								.header_type_image_small_top_image_background_colour
								.value
						)
					"
					style="transform: translateY(-70%)"
				/>
				<marquee-text
					v-if="
						fields.header_type_image_main_image_overlay_text_marquee
					"
					:repeat="6"
					:duration="
						getMarqueeScrollDuration(
							fields.header_type_image_main_image_overlay_text
						)
					"
					class="header-type-image-main-image-overlay-text scrolling-text text-cream absolute top-1/2 left-0 lg:left-28 xl:left-40 2xl:left-64 w-full z-20"
					>{{
						fields.header_type_image_main_image_overlay_text
					}}&nbsp;</marquee-text
				>
				<div
					class="sound-toggle w-10 h-10 cursor-pointer absolute bottom-0 hidden lg:block lg:left-28 xl:left-40 2xl:left-64 ml-2 mb-2 z-30"
					@click="toggleVideoSound(headerType)"
					@keypress="toggleVideoSound(headerType)"
				>
					<img
						alt="Image"
						loading="lazy"
						v-if="
							fields.header_type_image_main_media_type ===
								'video' &&
							!fields.header_type_image_video_desktop.endsWith(
								'.webp'
							)
						"
						src="@/assets/speaker-icon.svg"
						class=""
					/>
					<div
						v-if="headerVideoMuted"
						class="crossed w-full h-full absolute top-0 left-0"
					></div>
				</div>
			</div>
			<div
				class="w-full hidden lg:block h-60 -mt-44"
				:class="
					getBackgroundColour(
						fields.header_type_image_next_section_background_colour
							.value
					)
				"
			></div>
		</div>
		<div
			ref="videoContainer-image"
			class="hidden fixed top-0 left-0 w-full h-screen z-70 bg-black"
		>
			<x-icon
				class="text-cream w-8 lg:w-10 absolute top-10 right-10 z-70 cursor-pointer"
				@click="toggleVideo('close', headerType)"
			></x-icon>
			<video
				loading="lazy"
				preload="none"
				v-if="fields.header_type_image_main_media_type === 'video'"
				ref="headerVideo-image-popup"
				:src="fields.header_type_image_video_desktop"
				class="w-10/12 m-auto absolute absolute-center z-20"
				autoplay
				muted
				loop
				playsinline
			/>
		</div>
		<!--  Simple two column text and image/(s) -->
		<div v-if="headerType === 'wysiwyg'">
			<div
				class="flex justify-between flex-col lg:flex-row pt-12 pb-5 lg:pt-20 px-5 lg:px-28 xl:px-40 2xl:px-64 overflow-hidden"
			>
				<div
					class="w-10/12 lg:w-8/12 xl:w-8/12 pb-10 lg:pr-14 xl:pr-20 text-left"
				>
					<h4
						:class="getTextColour(fields.background_colour.value)"
						class="text-left"
					>
						{{ fields.header_type_wysiwyg_sub_title }}
					</h4>
					<h1
						:class="getTextColour(fields.background_colour.value)"
						class="text-left w-10/12 lg:w-full"
						v-html="fields.header_type_wysiwyg_title"
					></h1>
					<h4
						:class="getTextColour(fields.background_colour.value)"
						class="text-left"
						v-html="fields.header_type_wysiwyg_caption"
					></h4>
					<p
						:class="getTextColour(fields.background_colour.value)"
						class="text-left pt-4"
						v-html="fields.header_type_wysiwyg_content"
					></p>
					<div
						class="flex flex-col lg:flex-row lg:justify-start items-start lg:items-center"
					>
						<router-link
							v-if="
								fields.header_type_wysiwyg_show_button &&
								fields.header_type_wysiwyg_button_link_type ==
									'internal' &&
								!isExternalLink(
									fields.header_type_wysiwyg_button_link
								)
							"
							:to="
								generateUrl(
									fields.header_type_wysiwyg_button_link
								)
							"
							class="standard-button w-[180px] lg:w-[230px] lg:px-6 lg:mr-4 whitespace-nowrap"
							:class="
								getButtonStyle(
									fields.header_type_wysiwyg_button_colour
										.value
								)
							"
							>{{
								fields.header_type_wysiwyg_button_text
							}}</router-link
						>
						<a
							v-if="
								fields.header_type_wysiwyg_show_button &&
								fields.header_type_wysiwyg_button_link_type ==
									'external'
							"
							:href="
								generateUrl(
									fields.header_type_wysiwyg_button_link_external
								)
							"
							class="standard-button w-[180px] lg:w-[230px] lg:px-6 lg:mr-4 whitespace-nowrap"
							:class="
								getButtonStyle(
									fields.header_type_wysiwyg_button_colour
										.value
								)
							"
							>{{ fields.header_type_wysiwyg_button_text }}</a
						>
						<a
							v-if="
								fields.header_type_wysiwyg_show_button &&
								fields.header_type_wysiwyg_button_link_type ==
									'email' &&
								enquireEmailLink
							"
							:href="enquireEmailLink"
							class="standard-button w-[180px] lg:w-[230px] lg:px-6 lg:mr-4 whitespace-nowrap"
							:class="
								getButtonStyle(
									fields.header_type_wysiwyg_button_colour
										.value
								)
							"
							>{{ fields.header_type_wysiwyg_button_text }}</a
						>
						<router-link
							v-if="
								fields.header_type_wysiwyg_show_button_two &&
								fields.header_type_wysiwyg_button_two_link_type ==
									'internal' &&
								!isExternalLink(
									fields.header_type_wysiwyg_button_link_two
								)
							"
							:to="
								generateUrl(
									fields.header_type_wysiwyg_button_link_two
								)
							"
							class="standard-button w-[180px] lg:w-[230px] lg:px-6 mt-4 lg:mt-10 whitespace-nowrap"
							:class="
								getButtonStyle(
									fields.header_type_wysiwyg_button_colour_two
										.value
								)
							"
							>{{
								fields.header_type_wysiwyg_button_text_two
							}}</router-link
						>
						<a
							v-if="
								fields.header_type_wysiwyg_show_button_two &&
								fields.header_type_wysiwyg_button_two_link_type ==
									'external'
							"
							:href="
								generateUrl(
									fields.header_type_wysiwyg_button_link_two_external
								)
							"
							class="standard-button w-[180px] lg:w-[230px] lg:px-6 mt-4 lg:mt-10 whitespace-nowrap"
							:class="
								getButtonStyle(
									fields.header_type_wysiwyg_button_colour_two
										.value
								)
							"
							>{{ fields.header_type_wysiwyg_button_text_two }}</a
						>
					</div>
				</div>
				<div
					class="flex justify-end items-start lg:pt-8 w-full lg:w-4/12 xl:w-4/12 relative mb-10"
				>
					<div
						class="flex flex-col justify-end items-end z-30 mt-20 -mr-20"
					>
						<img
							alt="Image"
							loading="lazy"
							v-if="
								fields.header_type_wysiwyg_show_menu_highlight_icon
							"
							:src="
								fields.header_type_wysiwyg_menu_highlight_image
							"
							class="w-32 lg:w-36 mr-6"
						/>
						<img
							loading="lazy"
							alt="Image"
							:src="
								fields.header_type_wysiwyg_small_image.sizes
									.cote_image_size_2_portrait
							"
							class="c-image-offset top-left max-w-full mt-16"
							:class="
								getBackgroundColour(
									fields
										.header_type_wysiwyg_small_image_background_colour
										.value
								)
							"
						/>
					</div>
					<div class="relative">
						<img
							loading="lazy"
							alt="Image"
							:src="
								fields.header_type_wysiwyg_main_image.sizes
									.cote_image_size_4_portrait
							"
							class="max-w-full ml-auto relative z-20"
						/>
						<vertical-lines
							:lines="26"
							:border-width="'thin'"
							class="flex w-full h-full absolute left-2/3 top-[15%]"
						></vertical-lines>
					</div>
				</div>
			</div>
		</div>
		<!--  Faq header with search field -->
		<div v-if="headerType === 'faq'">
			<div
				class="flex justify-between flex-col lg:flex-row pt-10 lg:pt-20 px-5 lg:px-28 xl:px-40 2xl:px-64 overflow-hidden"
			>
				<div
					class="w-full lg:w-full xl:w-full pb-10 lg:pr-14 xl:pr-30 text-left relative"
				>
					<h4 class="text-left">
						{{ fields.header_type_faq_sub_title }}
					</h4>
					<h1
						class="text-brand text-left w-9/12 lg:w-full"
						v-html="fields.header_type_faq_title"
					></h1>
					<h4
						class="w-11/12 text-left mt-8"
						v-html="fields.header_type_faq_caption"
					></h4>
					<div class="flex mt-10 w-full xl:w-8/12 max-w-lg">
						<label
							for="faq-search"
							class="w-full cursor-pointer"
							name="faq-search"
						>
							<input
								class="px-4 py-4 w-full text-sm border-solid border border-brand placeholder-brand bg-transparent font-parisine"
								id="faq-search"
								type="text"
								v-model="searchValue"
								@keyup.enter="searchFaqs"
								:placeholder="
									fields.header_type_faq_search_placeholder
								"
							/>
						</label>
						<SearchIcon
							class="w-16 px-4 text-brand border-solid border border-brand border-l-0"
							@click="searchFaqs"
						></SearchIcon>
					</div>
				</div>
			</div>
		</div>
		<!--  Contact page header  -->
		<div v-if="headerType === 'contact'">
			<div
				class="w-full relative py-10 px-5 lg:px-28 xl:px-40 2xl:px-64 overflow-x-hidden"
			>
				<div class="flex justify-between items-start">
					<div class="w-full text-left">
						<h4>{{ fields.header_type_contact_small_text }}</h4>
						<h1 v-html="fields.header_type_contact_large_text"></h1>
					</div>
				</div>
				<div
					class="w-full grid grid-rows-1 grid-cols-1 lg:grid-cols-3 gap-8 lg:gap-20 mt-6 lg:mt-10 xl:mt-20"
				>
					<div
						v-for="(
							item, i
						) in fields.header_type_contact_content_blocks"
						:key="i"
						class="text-left w-10/12 lg:w-full"
					>
						<img
							alt="Image"
							loading="lazy"
							src="@/assets/active_menu.png"
							class="w-6 xl:w-8"
						/>
						<h4
							class="header-type-contact-content-block-title w-10/12"
						>
							{{ item.title }}
						</h4>
						<p
							class="header-type-contact-content-block-content"
							v-html="item.content"
						></p>
						<router-link
							v-if="
								item.show_button &&
								!isExternalLink(item.button_link)
							"
							:to="generateUrl(item.button_link)"
							class="standard-button mt-5 px-0 min-w-[170px] lg:min-w-[230px]"
							:class="getButtonStyle(item.button_colour.value)"
							>{{ item.button_text }}</router-link
						>
						<a
							v-if="
								item.show_button &&
								isExternalLink(item.button_link)
							"
							:href="generateUrl(item.button_link)"
							class="standard-button mt-5"
							:class="getButtonStyle(item.button_colour.value)"
							>{{ item.button_text }}</a
						>
					</div>
				</div>
			</div>
		</div>
		<!--  Newsletter page header  -->
		<div v-if="headerType === 'newsletter'">
			<div
				class="flex justify-between flex-col lg:flex-row pt-4 lg:pt-20 pb-20 px-4 lg:px-28 xl:px-40 2xl:px-64 overflow-hidden"
			>
				<div
					class="w-full lg:w-6/12 xl:w-6/12 pb-5 lg:pr-14 2xl:pr-28 text-left"
				>
					<h4 class="text-left newsletter-subtitle">
						{{ fields.header_type_newsletter_sub_title }}
					</h4>
					<h1
						class="text-left header-type-newsletter-title"
						v-html="fields.header_type_newsletter_title"
					></h1>
					<p
						class="text-left newsletter-content px-3 lg:px-0 mt-0 xl:mt-12"
						v-html="fields.header_type_newsletter_caption"
					></p>
					<news-letter-form :fields="fields"></news-letter-form>
				</div>
				<div
					class="w-full lg:w-6/12 xl:w-6/12 relative px-3 newsletter-right-section-container"
				>
					<img
						alt="Image"
						loading="lazy"
						v-if="fields.header_type_newsletter_animation"
						:src="fields.header_type_newsletter_animation"
						class="header-type-newsletter-animation"
					/>
					<div class="w-full relative">
						<img
							alt="Image"
							loading="lazy"
							:src="fields.header_type_newsletter_main_image"
							class="w-9/12 ml-auto relative z-20"
						/>
						<img
							alt="Image"
							loading="lazy"
							:src="fields.header_type_newsletter_small_image"
							class="c-image-offset bottom-right absolute bottom-0 w-2/5 z-30 header-type-newsletter-small-image"
							:class="
								getBackgroundColour(
									fields
										.header_type_newsletter_small_image_background_colour
										.value
								)
							"
						/>
						<vertical-lines
							:lines="30"
							:border-width="'thin'"
							class="flex w-9/12 h-[85%] absolute left-2/3 top-1/4"
						></vertical-lines>
					</div>
				</div>
			</div>
		</div>
		<!--  Occasion page header -->
		<div v-if="headerType === 'celebration'">
			<div
				class="flex justify-between flex-col lg:flex-row pt-12 pb-5 lg:pt-20 px-5 lg:px-28 xl:px-40 2xl:px-64 overflow-hidden"
			>
				<div
					class="w-10/12 lg:w-7/12 xl:w-6/12 pb-10 lg:pr-14 xl:pr-40 text-left"
				>
					<h4
						class="text-left"
						:class="getTextColour(fields.background_colour.value)"
					>
						{{
							fields.header_type_celebration_pretitle
								? fields.header_type_celebration_pretitle
								: celebration.acf.pretitle
						}}
					</h4>
					<h1
						class="text-left w-10/12"
						:class="getTextColour(fields.background_colour.value)"
						v-html="
							fields.header_type_celebration_celebration.acf.title
						"
					></h1>
					<h4
						class="text-left"
						:class="getTextColour(fields.background_colour.value)"
					>
						{{
							fields.header_type_celebration_celebration.acf
								.sub_title
						}}
					</h4>
					<p
						class="text-left pt-4"
						:class="getTextColour(fields.background_colour.value)"
						v-html="
							fields.header_type_celebration_celebration.acf
								.excerpt
						"
					></p>
					<div
						class="flex flex-row flex-wrap lg:justify-start items-start lg:items-center"
					>
						<router-link
							v-if="
								!isExternalLink(
									fields.header_type_celebration_celebration
										.acf.button_link
								)
							"
							:to="
								generateUrl(
									fields.header_type_celebration_celebration
										.acf.button_link
								)
							"
							class="standard-button mr-4 whitespace-nowrap"
							:class="
								getButtonStyle(
									fields.header_type_celebration_celebration
										.acf.button_colour.value
								)
							"
							>{{
								fields.header_type_celebration_celebration.acf
									.button_text
							}}</router-link
						>
						<a
							v-if="
								isExternalLink(
									fields.header_type_celebration_celebration
										.acf.button_link
								)
							"
							:href="
								generateUrl(
									fields.header_type_celebration_celebration
										.acf.button_link
								)
							"
							class="standard-button mr-4 whitespace-nowrap"
							:class="
								getButtonStyle(
									fields.header_type_celebration_celebration
										.acf.button_colour.value
								)
							"
							>{{
								fields.header_type_celebration_celebration.acf
									.button_text
							}}</a
						>
						<template
							v-if="
								fields.header_type_celebration_celebration.acf
									?.button2_link
							"
						>
							<router-link
								v-if="
									!isExternalLink(
										fields
											.header_type_celebration_celebration
											.acf.button2_link
									)
								"
								:to="
									generateUrl(
										fields
											.header_type_celebration_celebration
											.acf.button2_link
									)
								"
								class="standard-button mr-4 whitespace-nowrap"
								:class="
									getButtonStyle(
										fields
											.header_type_celebration_celebration
											.acf.button2_colour.value
									)
								"
								>{{
									fields.header_type_celebration_celebration
										.acf.button2_text
								}}</router-link
							>
							<a
								v-if="
									isExternalLink(
										fields
											.header_type_celebration_celebration
											.acf.button2_link
									)
								"
								:href="
									generateUrl(
										fields
											.header_type_celebration_celebration
											.acf.button2_link
									)
								"
								class="standard-button mr-4 whitespace-nowrap"
								:class="
									getButtonStyle(
										fields
											.header_type_celebration_celebration
											.acf.button2_colour.value
									)
								"
								>{{
									fields.header_type_celebration_celebration
										.acf.button2_text
								}}</a
							>
						</template>
					</div>
				</div>
				<div
					class="flex justify-end items-start lg:pt-8 w-full lg:w-5/12 xl:w-6/12 relative mb-10"
				>
					<div
						class="flex flex-col justify-end items-end z-30 mt-20 -mr-20"
					>
						<img
							alt="Image"
							loading="lazy"
							:src="
								fields.header_type_celebration_celebration.acf
									.animation
							"
							class="w-8/12 mr-6"
						/>
						<img
							alt="Image"
							loading="lazy"
							:src="
								fields.header_type_celebration_celebration.acf
									.small_image.sizes
									.cote_image_size_2_portrait
							"
							class="c-image-offset bottom-right max-w-full mt-16"
							:class="
								getBackgroundColour(
									fields
										.header_type_celebration_small_image_background_offset_colour
										.value
								)
							"
						/>
					</div>
					<div class="relative">
						<img
							alt="Image"
							loading="lazy"
							:src="
								fields.header_type_celebration_celebration.acf
									.large_image.sizes
									.cote_image_size_4_portrait
							"
							class="max-w-full ml-auto relative z-20"
						/>
						<vertical-lines
							:lines="26"
							:border-width="'thin'"
							class="flex w-full h-full absolute left-2/3 top-[15%]"
						></vertical-lines>
					</div>
				</div>
			</div>
		</div>
		<!--  Basic header  -->
		<div v-if="headerType === 'basic'">
			<div
				class="w-full relative py-10 lg:pb-14 px-5 lg:px-28 xl:px-40 2xl:px-64 overflow-x-hidden"
			>
				<div class="w-6/12 text-left">
					<h4>{{ fields.header_type_basic_small_text }}</h4>
					<h1 v-html="fields.header_type_basic_large_text"></h1>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NewsLetterForm from '@/components/NewsLetterForm.vue';
import { SearchIcon, XIcon } from '@heroicons/vue/solid';
import MarqueeText from 'vue-marquee-text-component';
import VerticalLines from '@/components/VerticalLines.vue';

export default {
	name: 'HeaderBlock',
	components: {
		NewsLetterForm,
		VerticalLines,
		SearchIcon,
		XIcon,
		MarqueeText,
	},
	props: {
		fields: {
			type: Object,
		},
	},
	data() {
		return {
			searchValue: '',
			headerVideoMuted: true,
			isVideo: true,
		};
	},
	computed: {
		enquireEmailLink() {
			return `mailto:${this.fields.header_type_wysiwyg_email_to}?subject=${this.fields.header_type_wysiwyg_email_subject}&body=${this.fields.header_type_wysiwyg_email_body}`;
		},
		headerType() {
			return this.fields.header_type.value;
		},
		headerTypeVideoMediaType() {
			if (this.fields.header_type_video_media_type.value !== 'video') {
				this.isVideo = false;
			}
			return this.fields.header_type_video_media_type.value;
		},
		headerTypeVideoMediaUrl() {
			return this.fields.header_type_video_media_type.value === 'image'
				? this.fields.header_type_video_image
				: this.fields.header_type_video_video;
		},
		headerTypeVideoMediaUrlMobile() {
			return this.fields.header_type_video_media_type.value === 'image'
				? this.fields.header_type_video_image_mobile
				: this.fields.header_type_video_video_mobile;
		},
		celebration() {
			if (this.fields.header_type_video_include_celebration) {
				return this.fields.header_type_video_celebration;
			}
			if (this.fields.header_type_celebration_celebration) {
				return this.fields.header_type_celebration_celebration;
			}
			return false;
		},
	},
	methods: {
		toggleVideoSound(headerType, isPopupVideo = false, type = null) {
			// The signature for this method is not particulary semantic,
			// as it's been expanded to include other required functionality.

			const headerVideoMobileRef = `headerVideoMobile-${headerType}`;
			const headerVideoRef = `headerVideo-${headerType}`;
			const headerVideoPopupRef = `${headerVideoRef}-popup`;

			// So we have all three versions
			const headerVideoMobile = this.$refs[headerVideoMobileRef];
			const headerVideo = this.$refs[headerVideoRef];
			const headerVideoPopup = this.$refs[headerVideoPopupRef];

			// If we're dealing with the mobile video
			if (
				headerVideoMobile &&
				window.getComputedStyle(headerVideoMobile).display !== 'none'
			) {
				headerVideoMobile.muted = !headerVideoMobile.muted;
				headerVideo.muted = true;
				this.headerVideoMuted = headerVideoMobile.muted;
			} else {
				// If we're dealing with the desktop video
				if (headerVideoMobile) {
					// If the mobile version exists, mute it
					headerVideoMobile.muted = true;
				}
				// If we're opening the popup
				if (isPopupVideo) {
					// Silence the main header video
					headerVideo.muted = true;
					this.headerVideoMuted = headerVideo.muted;

					// If we're opening the popup video
					if (type === 'open') {
						// Unmute and play the popup video
						headerVideoPopup.muted = false;
						headerVideoPopup.play();
					}
					// If we're closing the popup video or we're toggling sound on the header
					if (type === 'close' || type === null) {
						// Mute and pause the popup video
						headerVideoPopup.muted = true;
						headerVideoPopup.pause();
					}
				} else {
					// If we're just toggling sound on main header video
					headerVideo.muted = !headerVideo.muted;
					this.headerVideoMuted = headerVideo.muted;
				}
			}
		},

		getPaddingValue() {
			if (this.headerType === 'video') {
				return 'lg:pb-20';
			}
			if (this.headerType === 'image') {
				return '';
			}
			if (this.headerType === 'wysiwyg') {
				return 'pb-14 2xl:pb-24';
			}
		},
		searchFaqs() {
			if (this.searchValue.length <= 0) {
				alert('Please enter a search value');
				return false;
			}
			this.$emit('onFaqSearchEntered', this.searchValue);
		},
		equaliseContainerHeights(className) {
			const containers = document.getElementsByClassName(className);

			if (containers.length > 0) {
				const tempArray = [];

				for (let i = 0; i < containers.length; i += 1) {
					containers[i].style.height = 'auto';
					tempArray[i] = containers[i].scrollHeight;
				}

				const maxHeight = Math.max.apply(null, tempArray);

				for (let i = 0; i < containers.length; i += 1) {
					containers[i].style.height = `${maxHeight}px`;
				}
			}
		},
		resizeContainers() {
			const classNames = [
				'header-type-contact-content-block-title',
				'header-type-contact-content-block-content',
			];
			const self = this;

			classNames.forEach((className) => {
				self.equaliseContainerHeights(className);
			});
		},
		toggleVideo(type, headerType) {
			const ref = `videoContainer-${headerType}`;
			if (type === 'open') {
				this.$refs[ref].classList.remove('hidden');
			}
			if (type === 'close') {
				this.$refs[ref].classList.add('hidden');
			}
			this.toggleVideoSound(headerType, true, type);
		},
	},
	mounted() {
		this.equaliseContainerHeights(
			'header-type-contact-content-block-title'
		);
		this.equaliseContainerHeights(
			'header-type-contact-content-block-content'
		);
		window.addEventListener('resize', this.resizeContainers);
	},
};
</script>

<style>
.crossed {
	border-radius: 999%;
	background: linear-gradient(
			to top left,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) calc(50% - 1.2px),
			rgba(0, 0, 0, 1) 50%,
			rgba(0, 0, 0, 0) calc(50% + 1.2px),
			rgba(0, 0, 0, 0) 100%
		),
		linear-gradient(
			to top right,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) calc(50% - 1.2px),
			rgba(0, 0, 0, 1) 50%,
			rgba(0, 0, 0, 0) calc(50% + 1.2px),
			rgba(0, 0, 0, 0) 100%
		);
}
@media screen(lg) {
	.header-type-image-main-image-overlay-text {
		width: calc(100% - 7rem);
	}
}

@media screen(xl) {
	.header-type-image-main-image-overlay-text {
		width: calc(100% - 10rem);
	}
}

@media screen(2xl) {
	.header-type-image-main-image-overlay-text {
		width: calc(100% - 16rem);
	}
}
</style>
